import history from "../history";

import { GROUP_LIST, GET_PROJECT_PROGRESS, MBS_IMAGE_ALERT, UPLOAD_IMAGE, DELETE_IMAGE_ALERT } from 'constants/actionTypes.jsx';
import { LOAD_FORM_GROUP, SHOW_GROUP, EDIT_GROUP, SUCCESSFULL_EDIT, NEW_GROUP, DELETE_GROUP, SUCCESSFULL_NEW, GROUP_PROGRAM } from 'constants/actionTypes';
import { SUCCESSFULL_REDIRECT } from 'constants/actionTypes';
import { convertDate } from 'assets/functions/general.jsx';
import { SA_FILE_ALERT } from 'constants/actionTypes';
import { UPLOAD_SA_FILE } from 'constants/actionTypes';
import { GROUP_DELETE_WARNING } from 'constants/actionTypes';
import { ACTION_WARNING } from 'constants/actionTypes';
import { LIST_INTRODUCTION } from 'constants/actionTypes';
import { NEW_INTRODUCTION } from 'constants/actionTypes';
import { SHOW_INTRODUCTION } from 'constants/actionTypes';
import { LOAD_FORM_INTRODUCTION } from 'constants/actionTypes';
import { DELELTE_INTRODUCTION } from 'constants/actionTypes';
import { DELETE_SUCCESSFUL } from 'constants/actionTypes';
import { UPDATE_GROUP_PAGINATION } from "constants/actionTypes";
import { CLEAN_LOADING_SEARCH } from "constants/actionTypes";
import { LOADING_GROUP } from "constants/actionTypes";
import { PAGINATED_GROUP_LIST } from "constants/actionTypes";
import { RESET_WAITING_RESPONSE } from "constants/actionTypes";
import { GROUP_CUSTOMIZED_URL } from "constants/actionTypes";
import { CLEAR_GROUP_CUSTOMIZED_URL } from "constants/actionTypes";
import { SESSION_EXPIRED } from "constants/actionTypes";

export const getGroupList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/group/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                dispatch({ type: GROUP_LIST, payload: json.data });
            })
    }
}

export const getGroupPaginationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
        urlencoded.append("key", reduxState.groupReducer.group_list_pagination.keyword);
        urlencoded.append("page", reduxState.groupReducer.group_list_pagination.page);
        urlencoded.append("page_size", reduxState.groupReducer.group_list_pagination.page_size);
        urlencoded.append("order_column", reduxState.groupReducer.group_list_pagination.order_column);
        urlencoded.append("order_direction", reduxState.groupReducer.group_list_pagination.order_direction);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/group/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_GROUP_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const updateGroupPagination = params => {
    return (dispatch) => {
        dispatch({ type: UPDATE_GROUP_PAGINATION, payload: params });
    }
}

export const getGroupProgram = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("program", "option.program2");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/group/program", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GROUP_PROGRAM, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showGroup = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        return fetch(reduxState.loginReducer.base_url + "/group/show/" + key + "?callback=foo")
            .then(response => response.json())
            .then(json => {
                dispatch({ type: SHOW_GROUP, payload: json.data });
                dispatch({ type: LOAD_FORM_GROUP, data: json.data });
            })
    }
};

export const loadFormGroup = data => ({ type: LOAD_FORM_GROUP, data });
export const editGroup = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.groupform.values.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.groupform.values.name);
        urlencoded.append("startDate", convertDate(reduxState.form.groupform.values.start_date));
        urlencoded.append("finalDate", convertDate(reduxState.form.groupform.values.final_date));
        urlencoded.append("graduationDate", convertDate(reduxState.form.groupform.values.graduation_date));
        urlencoded.append("modality", reduxState.form.groupform.values.modality);
        urlencoded.append("program", reduxState.form.groupform.values.program);
        urlencoded.append("interweaveLocal", reduxState.form.groupform.values.interweave_local !== undefined ? reduxState.form.groupform.values.interweave_local : "");
        urlencoded.append("authorizationCode", reduxState.form.groupform.values.authorization_code !== undefined ? reduxState.form.groupform.values.authorization_code : "");
        urlencoded.append("name_image", reduxState.form.groupform.values.name_image);
        urlencoded.append("number_students_graduated", reduxState.form.groupform.values.number_students_graduated);
        urlencoded.append("type", reduxState.form.groupform.values.type);
        urlencoded.append("enrollment_source", reduxState.form.groupform.values.enrollment_source);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/group/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_GROUP, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGroup = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.form.groupNewform.values.id_ambassador);
        urlencoded.append("name", reduxState.form.groupNewform.values.name);
        urlencoded.append("startDate", convertDate(reduxState.form.groupNewform.values.start_date));
        urlencoded.append("finalDate", convertDate(reduxState.form.groupNewform.values.final_date));
        urlencoded.append("modality", reduxState.form.groupNewform.values.modality);
        urlencoded.append("program", reduxState.form.groupNewform.values.program);
        urlencoded.append("graduationDate", reduxState.form.groupNewform.values.graduation_date);
        urlencoded.append("interweaveLocal", reduxState.form.groupNewform.values.interweave_local !== undefined ? reduxState.form.groupNewform.values.interweave_local : "");
        urlencoded.append("authorizationCode", reduxState.form.groupNewform.values.authorization_code !== undefined ? reduxState.form.groupNewform.values.authorization_code : "");
        urlencoded.append("name_image", reduxState.form.groupNewform.values.name_image);
        urlencoded.append("number_students_graduated", reduxState.form.groupNewform.values.number_students_graduated);
        urlencoded.append("type", reduxState.form.groupNewform.values.type);
        urlencoded.append("enrollment_source", reduxState.form.groupNewform.values.enrollment_source);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/group/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_GROUP, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                    dispatch({ type: RESET_WAITING_RESPONSE });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const deleteGroup = (key, redirect) => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/group/delete/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    if (json.code === 400) {
                        dispatch({ type: GROUP_DELETE_WARNING })
                    }
                    else {
                        dispatch({ type: DELETE_GROUP, payload: json.data });
                        redirect.push('/group');
                    }
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getProjectProgress = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/programmbs/progress/group/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_PROJECT_PROGRESS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const uploadImageAlert = (key) => ({ type: MBS_IMAGE_ALERT, payload: key })

export const uploadSaFileAlert = (key) => ({ type: SA_FILE_ALERT, payload: key })

export const uploadImage = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.groupReducer.id_student);
        urlencoded.append("file_name", reduxState.form.uploadform.values.name_image);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/programmbs/newfile", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UPLOAD_IMAGE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const uploadWellnessParticipantImage = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.groupReducer.id_student);
        urlencoded.append("file_name", reduxState.form.uploadform.values.name_image);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/wellnessparticipant/newfile", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UPLOAD_IMAGE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const uploadSaFile = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.groupReducer.id_student);
        urlencoded.append("file_name", reduxState.form.uploadform.values.sa_file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/programsa/newfile", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UPLOAD_SA_FILE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const deleteImageAlert = () => ({ type: DELETE_IMAGE_ALERT })

export const showGroupRedirect = (redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.groupReducer.new_group.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return redirect.push('/group/show/' + key);
    }
}
export const groupCustomizedUrl = (groupId, language) => {
    return (dispatch) => {
        let data = { id: groupId, language: language }
        dispatch({ type: GROUP_CUSTOMIZED_URL, payload: data })
        dispatch({ type: ACTION_WARNING })
    }
};
export const clearGroupCustomizedUrl = () => ({ type: CLEAR_GROUP_CUSTOMIZED_URL });

export const listIntroduction = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/introduction", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LIST_INTRODUCTION, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showGroupIntroduction = (group, lang) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        return fetch(reduxState.loginReducer.base_url + "/introduction/show/group/" + group + "?callback=foo")
            .then(response => response.json())
            .then(json => {
                if (json.code !== 500) {
                    dispatch({ type: SHOW_INTRODUCTION, payload: json.data });
                    dispatch({ type: LOAD_FORM_INTRODUCTION, data: json.data });
                }
                else {
                    switch (lang) {
                        case "en":
                            dispatch(showIntroduction("1"))
                            break;
                        case "es":
                            dispatch(showIntroduction("2"))
                            break;
                        case "fr":
                            dispatch(showIntroduction("3"))
                            break;
                        case "pt":
                            dispatch(showIntroduction("4"))
                            break;
                        case "zh":
                            dispatch(showIntroduction("5"))
                            break;
                        default:
                            break;
                    }
                }
            })
    }
};

export const showIntroduction = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        return fetch(reduxState.loginReducer.base_url + "/introduction/show/" + key + "?callback=foo")
            .then(response => response.json())
            .then(json => {
                dispatch({ type: SHOW_INTRODUCTION, payload: json.data });
                dispatch({ type: LOAD_FORM_INTRODUCTION, data: json.data });
            });
    }
};

export const newGroupIntroduction = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("group_id", reduxState.groupReducer.show_group.id);
        urlencoded.append("content", reduxState.form.introductionNewform.values.content);
        urlencoded.append("language", reduxState.groupReducer.show_group.embassador.language);
        urlencoded.append("type", "group");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/introduction/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_INTRODUCTION, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editIntroduction = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.introductionEditform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("group_id", reduxState.groupReducer.show_introduction.group !== undefined ? reduxState.groupReducer.show_introduction.group.id : "");
        urlencoded.append("content", reduxState.form.introductionEditform.values.content);
        urlencoded.append("language", reduxState.form.introductionEditform.values.language);
        urlencoded.append("type", reduxState.form.introductionEditform.values.type);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/introduction/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_GROUP, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const deleteIntroduction = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/introduction/delete/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELELTE_INTRODUCTION, payload: json.data });
                    dispatch({ type: DELETE_SUCCESSFUL });
                    dispatch(listIntroduction());
                    history.push('/group/introduction');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const loadingGroup = () => ({ type: LOADING_GROUP })

import { SUCCESSFULL_EDIT } from 'constants/actionTypes';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { STUDENT_WELLNESS_LIST } from 'constants/actionTypes';
import { SHOW_PROFILE_UPDATE } from 'constants/actionTypes';
import { STUDENT_MBS_LIST, STUDENT_AMBASSADOR_LIST, FUTURE_AMBASSADOR_LIST, AMBASSADOR_STUDENT_LIST } from 'constants/actionTypes.jsx';
import history from '../history';
import { STUDENT_WELLNESS_PARTICIPANT_LIST } from 'constants/actionTypes';
import { STUDENT_EXPRESS_LIST } from 'constants/actionTypes';
export const getAdminStudentMbsList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", "state.revision");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/mbs?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_MBS_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getStudentExpressList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", reduxState.loginReducer.active_user.roles.some(role =>["ROLE_EMBASSADOR"].includes(role)) ? "state.correction"  : "state.revision");
    urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/express?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_EXPRESS_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getStudentMbsList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", reduxState.loginReducer.active_user.roles.includes("ROLE_EMBASSADOR") ? "state.correction"  : "state.revision");
    urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/mbs?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_MBS_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getStudentAmbassadorList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", "state.revision");
    urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/ambassador?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_AMBASSADOR_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getStudentWellnessList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", "state.revision");
    urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/wellness?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_WELLNESS_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getStudentWellnessParticipantList = (state) => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("state", state);
    urlencoded.append("ambassador", reduxState.loginReducer.active_user.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/wellnessparticipant?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: STUDENT_WELLNESS_PARTICIPANT_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const getFutureAmbassadorList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/futureambassador?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: FUTURE_AMBASSADOR_LIST, payload: json.data });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

export const confirmGroup = (params, redirect) => {
  return (dispatch, getState) => {
    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    var group = params.group;
    var student = params.student;

    urlencoded.append("group", group.toString());
    urlencoded.append("student", student.toString());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(reduxState.loginReducer.base_url + "/student/confirmgroup?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch(clearPending(params, redirect));
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }

};

export const confirmGroupAmbassadorStudent = (params, redirect) => {
  return (dispatch, getState) => {
    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    var group = params.group;
    var student = params.student;

    urlencoded.append("group", group.toString());
    urlencoded.append("student", student.toString());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(reduxState.loginReducer.base_url + "/student/confirmgroup?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: SUCCESSFULL_EDIT });
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }

};

export const clearPending = (params, redirect) => {
  return (dispatch, getState) => {
    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);


    var urlencoded = new URLSearchParams();
    var student = params.student;
    urlencoded.append("student", student.toString());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(reduxState.loginReducer.base_url + "/student/deletefutureambassador?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          redirect.push('/dashboard');
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }

};

export const getAmbassadorStudentList = () => {
  return (dispatch, getState) => {

    const reduxState = getState();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
    urlencoded.append("ambassador", reduxState.loginReducer.active_user.id);
    urlencoded.append("state", "state.correction");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    return fetch(reduxState.loginReducer.base_url + "/student/ambassador?callback=foo", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.code === 401 ) {
          dispatch({ type: SESSION_EXPIRED });
          history.push("/login");
        } else {
          dispatch({ type: AMBASSADOR_STUDENT_LIST, payload: json.data });
          if (reduxState.loginReducer.active_user.idnumber === "undefined" || reduxState.loginReducer.active_user.idnumber === undefined) {
            dispatch({ type: SHOW_PROFILE_UPDATE })
          }
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }
};

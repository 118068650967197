import { SUCCESSFULL_AUTHENTICATION, FAILED_AUTHENTICATION, SUCCESSFULL_ACTIVE_USER, LOGOUT_USER, CLEAN_AUTHENTICATION, SWITCH_BASE_URL } from 'constants/actionTypes.jsx';
import $ from 'jquery';
import { setLanguage } from 'react-switch-lang';
import { getGroupProgram } from "actions/groupActions.jsx";
import { grantAmbassadorList } from "actions/grantActions.jsx";
import { globalNumbers } from "actions/reportActions.jsx";
import { LOGGIN_IN } from 'constants/actionTypes';
import { SUCCESSFUL_CONNECTION } from 'constants/actionTypes';
import { getAdminStudentMbsList } from './dashboardActions';
import { AVATAR_AUTHENTICATION_WARNING } from 'constants/actionTypes';
import { CLEAN_AVATAR_AUTHENTICATION_WARNING } from 'constants/actionTypes';
import { getAmbassadorRecognitionLevel, getAmbassadorState } from './ambassadorActions';
import history from '../history';


export const getAuthenticatedUser = (data) => {
    return async (dispatch, getState) => {

        dispatch({ type: SUCCESSFUL_CONNECTION });
        const updatedReduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + data.token);

        var urlencodedactive = new URLSearchParams();

        const requestOptionsActive = {
            method: 'POST',
            headers: myHeaders,
            body: urlencodedactive,
            redirect: 'follow',
        };


        return fetch(updatedReduxState.loginReducer.base_url + "/user/active_user", requestOptionsActive)
            .then(response => {
                return response.json()
            })
            .then(response => {
                dispatch({ type: SUCCESSFULL_AUTHENTICATION, payload: data });
                dispatch({ type: SUCCESSFULL_ACTIVE_USER, payload: response });
                const active_user = response.data;

                dispatch(getAmbassadorState(active_user.id)).then(() => {
                    const ambassador_state = getState().ambassadorReducer.ambassador_state;
                    if (active_user.roles.includes("ROLE_STUDENT")) {
                        history.push('/course/list');
                    }
                    else if (active_user.roles.includes("ROLE_STUDENT_EMBASSADOR")) {
                        history.push('/dashboard/student');
                    }
                    else if (active_user.roles.includes("ROLE_LANGUAGE_ADMIN") || active_user.roles.includes("ROLE_ADMIN")) {
                        dispatch(getAdminStudentMbsList())
                        dispatch(grantAmbassadorList());
                        dispatch(getGroupProgram());
                        dispatch(globalNumbers());
                        history.push('/dashboard');
                    } else if (active_user.roles.includes("ROLE_EMBASSADOR")) {
                        dispatch(getAmbassadorRecognitionLevel(active_user.id));
                        if (parseInt(ambassador_state.days_interval, 10) > 365) {
                            history.push("/course/list");
                        }
                        else {
                            history.push('/dashboard');
                        }
                    }
                    else {
                        history.push('/dashboard');
                    }
                })
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getAuthenticacion = (params) => {

    // Temp Init
    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('login_failed'));
        }, 15000);
    });


    return async (dispatch, getState) => {
        const reduxState = getState();
        var urlencoded = new URLSearchParams();
        urlencoded.append("_username", params.username);
        urlencoded.append("_password", params.password);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
        };


        dispatch({ type: CLEAN_AUTHENTICATION });
        dispatch({ type: LOGGIN_IN });

        Promise.race([
            fetch(reduxState.loginReducer.init_base_url + "/api/login_check", requestOptions),
            timeoutPromise
        ])
            .then(response => response.json())
            .then(data => {
                if (data.code === 401) {
                    dispatch({ type: SUCCESSFUL_CONNECTION });
                    dispatch({ type: FAILED_AUTHENTICATION });
                }
                else {
                    dispatch(getAuthenticatedUser(data));
                }

            })
            .catch(error => {
                dispatch({ type: SWITCH_BASE_URL });
                if (error.message === 'login_failed') {
                    const requestOptionsBackup = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow',
                    };

                    return fetch(reduxState.loginReducer.backup_base_url + "/api/login_check", requestOptionsBackup)
                }
            })
            .then(backup_response => {
                return backup_response.json();
            })
            .then(backup_data => {
                if (backup_data.code === 401) {
                    dispatch({ type: SUCCESSFUL_CONNECTION });
                    dispatch({ type: FAILED_AUTHENTICATION });
                }
                else {
                    dispatch(getAuthenticatedUser(backup_data));
                }

            })
            .catch(error2 => {
                //dispatch({ type: SUCCESSFUL_CONNECTION });
                //dispatch({ type: FAILED_AUTHENTICATION });
            });

    };
};

export const getAvatarAuthenticacion = (id) => {


    return (dispatch, getState) => {
        const reduxState = getState();

        var settings = {
            "url": reduxState.loginReducer.base_url + "/api/ota",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + reduxState.loginReducer.token
            },
            "data": {
                "user_id": id,
            }
        }

        dispatch(cleanState());
        dispatch({ type: CLEAN_AUTHENTICATION });
        dispatch({ type: LOGGIN_IN });


        return $.ajax(settings)
            .done(function (token_info) {
                dispatch({ type: SUCCESSFUL_CONNECTION });
                const updatedReduxState = getState();
                const parsed_info = JSON.parse(token_info);

                var settings = {
                    "url": updatedReduxState.loginReducer.base_url + "/user/active_user",
                    "method": "POST",
                    "timeout": 0,
                    "headers": {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Bearer " + parsed_info.token
                    },
                    "data": {
                    }
                };
                return $.ajax(settings)
                    .done(function (response) {


                        dispatch({ type: SUCCESSFULL_AUTHENTICATION, payload: parsed_info });
                        dispatch({ type: SUCCESSFULL_ACTIVE_USER, payload: JSON.parse(response) });
                        const active_user = JSON.parse(response).data;
                        dispatch(getAmbassadorState(active_user.id))
                            .then(() => {
                                const ambassador_state = getState().ambassadorReducer.ambassador_state;
                                if (active_user.roles.includes("ROLE_STUDENT")) {

                                    history.push('/course/list');
                                }
                                else if (active_user.roles.includes("ROLE_STUDENT_EMBASSADOR")) {

                                    history.push('/dashboard/student');

                                }
                                else if (active_user.roles.includes("ROLE_LANGUAGE_ADMIN") || active_user.roles.includes("ROLE_ADMIN")) {
                                    dispatch(getAdminStudentMbsList())
                                    dispatch(grantAmbassadorList());
                                    dispatch(getGroupProgram());
                                    dispatch(globalNumbers());
                                    history.push('/dashboard');
                                }
                                else if (active_user.roles.includes("ROLE_EMBASSADOR")) {
                                    dispatch(getAmbassadorRecognitionLevel(active_user.id));
                                    if (parseInt(ambassador_state.days_interval, 10) > 365) {
                                        history.push("/course/list");
                                    }
                                    else {
                                        history.push('/dashboard');
                                    }
                                }
                                else {
                                    history.push('/dashboard');
                                }
                            })

                    })
                    .fail(function (response) {
                        history.push('/login');
                    });


            })
            .fail(function (response) {
                dispatch({ type: SUCCESSFUL_CONNECTION });
                dispatch({ type: FAILED_AUTHENTICATION });
            });
    }

}

export const getFirstRegisterAuthentication = (id) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var settings = {
            "url": reduxState.loginReducer.base_url + "/api/register/ota",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            "data": {
                "user_id": id,
            }
        }
        dispatch(cleanState());
        dispatch({ type: CLEAN_AUTHENTICATION });
        dispatch({ type: LOGGIN_IN });
        return $.ajax(settings)
            .done(function (token_info) {
                dispatch({ type: SUCCESSFUL_CONNECTION });
                const updatedReduxState = getState();
                const parsed_info = JSON.parse(token_info);

                var settings = {
                    "url": updatedReduxState.loginReducer.base_url + "/user/active_user",
                    "method": "POST",
                    "timeout": 0,
                    "headers": {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Bearer " + parsed_info.token
                    },
                    "data": {
                    }
                };
                return $.ajax(settings)
                    .done(function (response) {
                        dispatch({ type: SUCCESSFULL_AUTHENTICATION, payload: parsed_info });
                        dispatch({ type: SUCCESSFULL_ACTIVE_USER, payload: JSON.parse(response) });
                        history.push('/course/list');
                    })
                    .fail(function () {
                        history.push('/login');
                    });
            })
            .fail(function () {
                dispatch({ type: SUCCESSFUL_CONNECTION });
                dispatch({ type: FAILED_AUTHENTICATION });
            });
    }
}

export const getActiveUser = () => {
    return (dispatch, getState) => {

        const reduxState = getState();


        var settings = {
            "url": reduxState.loginReducer.base_url + "/user/active_user",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + reduxState.loginReducer.token
            },
            "data": {
            }
        };

        return $.ajax(settings)
            .done(function (response) {
                dispatch({ type: SUCCESSFULL_ACTIVE_USER, payload: JSON.parse(response) });
                setLanguage(reduxState.loginReducer.active_user.language);

            })
            .fail(function (response) {
                history.push('/login');
            });
    }

}

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_USER });
        history.push('/login');

    }

}
export const avatarAuthenticationWarning = (params) => ({ type: AVATAR_AUTHENTICATION_WARNING, payload: params })
export const cleanAvatarAuthenticationWarning = () => ({ type: CLEAN_AVATAR_AUTHENTICATION_WARNING })

export const cleanState = () => ({ type: LOGOUT_USER })

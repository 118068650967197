/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ token, component: Component, ...rest }) {
  return (
    <Route {...rest} > {token !== undefined ? <Component /> : <Redirect to="/login" />} </Route>
  );
}

const mapStateToProps = state => ({
  token: state.loginReducer.token
});

export default connect(mapStateToProps, null)(PrivateRoute);


import { STUDENT_LIST, SHOW_STUDENT, LOAD_FORM_STUDENT, DASHBOARD_STUDENT } from "constants/actionTypes";
import { DELETE_STUDENT } from "constants/actionTypes";
import { ERROR_EDIT_STUDENT } from "constants/actionTypes";
import { VIDEO_PROMOTION } from "constants/actionTypes";
import { INACTIVE_STUDENT_LIST } from "constants/actionTypes";
import { UNIT_STUDENT_LIST } from "constants/actionTypes";
import { STUDENT_LIST_COURSE } from "constants/actionTypes";
import { UPDATE_PARTICIPANT_NAME } from "constants/actionTypes";
import { NEW_STOP } from "constants/actionTypes";
import { RESET_SHOW_STOP } from "constants/actionTypes";
import { GROUP_STUDENT_LIST } from "constants/actionTypes";
import { PAGINATED_STUDENT_LIST } from "constants/actionTypes";
import { PAGINATED_SUCCESS_STORY_LIST } from "constants/actionTypes";
import { PAGINATED_STUDENT_GRANTMEMBER_LIST } from "constants/actionTypes";
import { PAGINATED_STUDENT_AMBASSADOR_LIST } from "constants/actionTypes";
import { PAGINATED_POTENTIALSA_LIST } from "constants/actionTypes";
import { LOADING_STUDENT } from "constants/actionTypes";
import { PROGRESS_STATE_LIST } from "constants/actionTypes";
import { COURSE_STUDENT_STOP_LIST } from "constants/actionTypes";
import { SHOW_STOP } from "constants/actionTypes";
import { EDIT_PROGRESS } from "constants/actionTypes";
import { ENDROLLED_COURSE_PARTICIPANT_LIST } from "constants/actionTypes";
import { NEW_PROGRESS } from "constants/actionTypes";
import { AVAILABLE_COURSE_LIST } from "constants/actionTypes";
import { SEARCH_LOADING } from "constants/actionTypes";
import { SUCCESS_STORY } from "constants/actionTypes";
import { EDIT_STUDENT } from "constants/actionTypes";
import { NEW_STUDENT } from "constants/actionTypes";
import { EDIT_PASSWORD_STUDENT } from "constants/actionTypes";
import { GET_STUDENT_AMBASSADOR, EVALUATION_PRE, EVALUATION_POST, MBS_STUDENT_LIST} from "constants/actionTypes";

const initialState = { 
  student_list: [], 
  inactive_student_list:[],
  student_ambassador_list:[],
  success_story:[],
  video_promotion:[],
  mbs_student_list:[],
  available_course_list: [],
  unit_student_list:[],
  student_course_list:[],
  enrolled_course_participant_list:[],
  loading: true,
  editError: false,
  search_loading: false,
  evaluation_pre:{
    question1:"option2",
    question2:"option1",
    question3:"option1",
    question4:"option1",
    question5:"option1",
    question6:"option1",
    question7:"option1"
  },
  evaluation_post:{
    postquestion1:"option1",
    postquestion2:"option1",
    postquestion3:"option1",
    postquestion4:"option1",
    postquestion5:"option1",
    postquestion6:"option1",
    postquestion7:"option1",
    postquestion8:"option1",
    postquestion9:"option1",
  },
  show_student: {
    id:"",
    email:"",
    username:"",
    language:"",
    language_grader:"",
    first_name:"",
    last_name: "",
    country:"",
    areacode:"USA +1",
  },
  new_student:{
    id:"",
    language: "es",
    country: "AFG",
    created_at: new Date(),
    studentgroup:{
      group:{
        embassador:{
          first_name:"",
          last_name:""
        },
        name:""
      }
    }
  },
  dashboard_student:{    
      progressMbs:{
        state: "new",
        plan:  "0%",
        process: "0%",
        product: "0%",
        price: "0%",
        promotion: "0%",
        paperwork: "0%",
        quality: "0%",
        service: "0%"
      },
      progressSa:{
        state: "new",
        mision:  "0%",
        generate: "0%",
        facilitate: "0%",
        graduate: "0%",
        support: "0%", 
        pilot: "0%",  
        student_ambassador: false  
      },
      participant_name: "",
  },
  show_stop:{
    course_id:"",
    student_id:"",
    principle_id:"", 
  },
  new_stop:{
    course_id:"",
    student_id:"",
    principle_id:"", 
    state:"",
  },    
  course_student_stop_list:[],
  group_student_list:[],
  paginated_student_list: [],
  student_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "created_at",
    order_direction: "DESC",
  },
  paginated_potentialsa_list: [],
  potentialsa_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "created_at",
    order_direction: "DESC",
  },
  paginated_success_story_list: [],
  success_story_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "approval_date",
    order_direction: "DESC",
  },
  paginated_student_course_list:[],
  student_course_list_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "enrolled",
    order_direction: "DESC",
  },
  paginated_student_grantmember_list:[],
  student_grantmember_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "created_at",
    order_direction: "DESC",
  },
  paginated_student_ambassador_list:[],
  student_ambassador_pagination_params:{
    keyword: "",
    page:  1,
    page_size: 10,
    order_column: "created_at",
    order_direction: "DESC",
  },
  pages_total: "1",
  search_loading: false,
}

export const studentReducer = (state = initialState, action) => {
    switch (action.type) {
      case STUDENT_LIST:
        return Object.assign({}, state, {
          student_list: action.payload,
          loading: false,
          search_loading: false,
        });

      case MBS_STUDENT_LIST:
        return Object.assign({}, state, {
          mbs_student_list: action.payload,
          loading: false
        });
    
      case SHOW_STUDENT:
          return Object.assign({}, state, {
            show_student: action.payload
          });
    
      case LOAD_FORM_STUDENT:
        return Object.assign({}, state, {
          data: action.data
        });
    
      case DELETE_STUDENT:
          return Object.assign({}, state, {
            delete_student: action.payload
          }); 
    
      case EDIT_STUDENT:
          return Object.assign({}, state, {
            edit_student: action.payload
          }); 
      case NEW_STUDENT:
        return Object.assign({}, state, {
          new_student: action.payload
        }); 
      case ERROR_EDIT_STUDENT:
        return Object.assign({}, state, {
          editError: true
        })
    
      case EDIT_PASSWORD_STUDENT:
          return Object.assign({}, state, {
            edit_password: action.payload
          }); 

      case DASHBOARD_STUDENT:
        return Object.assign({}, state, {
          dashboard_student: action.payload
        }); 

      case GET_STUDENT_AMBASSADOR:
        return Object.assign({}, state, {
          student_ambassador_list: action.payload,
          loading: false
        }); 
      case EVALUATION_PRE:
        return Object.assign({}, state, {
          evaluation_pre: action.payload,
        });
        
      case EVALUATION_POST:
        return Object.assign({}, state, {
          evaluation_post: action.payload,
        });
      
      case SUCCESS_STORY:
        return Object.assign({}, state, {
          success_story: action.payload,
          loading: false
        });

      case VIDEO_PROMOTION:
        return Object.assign({}, state, {
          video_promotion: action.payload,
          loading: false
        });
      case SEARCH_LOADING:
          return Object.assign({}, state, {
            search_loading: true,
          });
      case INACTIVE_STUDENT_LIST:
        return Object.assign({}, state, {
          inactive_student_list: action.payload,
          loading: false,
          search_loading: false,
        });
      case AVAILABLE_COURSE_LIST:
        return Object.assign({}, state, {
          available_course_list: action.payload,
        });
      case UNIT_STUDENT_LIST:
        return Object.assign({},state, {
          unit_student_list: action.payload,
        });
      case NEW_PROGRESS:
        return Object.assign({},state,{
          new_progress: action.payload,
        })
      case EDIT_PROGRESS:
        return Object.assign({},state,{
          edit_progress: action.payload,
        })
      case STUDENT_LIST_COURSE:
        return Object.assign({}, state, {
          paginated_student_course_list: action.payload.list,
          pages_total: action.payload.pages_total,
          loading: false
        });
      case ENDROLLED_COURSE_PARTICIPANT_LIST:
        return Object.assign({}, state, {
          enrolled_course_participant_list:action.payload,
          loading: false,
          search_loading: false,
        });
        case UPDATE_PARTICIPANT_NAME:
          return Object.assign({},state,{
            participant_name: action.payload,
          })
        case NEW_STOP:
          return Object.assign({}, state, {
            new_stop: action.payload,
          });
        case SHOW_STOP:
          return Object.assign({}, state, {
            show_stop: action.payload,
          }); 
        case RESET_SHOW_STOP:
          return Object.assign({}, state, {
            show_stop: { 
              course_id:"",
              student_id:"",
              principle_id:"", 
            },
          });
        case COURSE_STUDENT_STOP_LIST:
          return Object.assign({}, state, {
            course_student_stop_list: action.payload,
          }); 
        case GROUP_STUDENT_LIST:
          return Object.assign({}, state, {
            group_student_list: action.payload,
            loading: false
          });
        case PAGINATED_STUDENT_LIST:
          return Object.assign({}, state, {
            paginated_student_list: action.payload.list,
            pages_total: action.payload.pages_total,
            loading: false
          });
        case PAGINATED_POTENTIALSA_LIST:
          return Object.assign({}, state, {
            paginated_potentialsa_list: action.payload.list,
            pages_total: action.payload.pages_total,
            loading: false
          });

        case PAGINATED_SUCCESS_STORY_LIST:
          return Object.assign({}, state, {
            paginated_success_story_list: action.payload.list,
            pages_total: action.payload.pages_total,
            loading: false
          });

        case PAGINATED_STUDENT_GRANTMEMBER_LIST:
          return Object.assign({}, state, {
            paginated_student_grantmember_list: action.payload.list,
            pages_total: action.payload.pages_total,
            loading: false
          });

        case PAGINATED_STUDENT_AMBASSADOR_LIST:
          return Object.assign({}, state, {
            paginated_student_ambassador_list: action.payload.list,
            pages_total: action.payload.pages_total,
            loading: false
          });

        case LOADING_STUDENT: 
          return Object.assign({}, state, {
            loading: true
          });   
    }
    return state;
}
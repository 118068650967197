import { SHOW_TRACKING } from "constants/actionTypes";
import { LIST_TRACKING } from "constants/actionTypes";

const initialState = {
  show_tracking: [],
  tracking_list: [],

}

export const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_TRACKING:
      return Object.assign({}, state, {
        tracking_list: action.payload,
        loading: false
      });
    case SHOW_TRACKING:
      return Object.assign({}, state, {
        show_tracking: action.payload,
      });
  }

  return state;
}
import React from "react";
import ReactDOM from "react-dom";
import PrivateRoute from "components/Route/PrivateRoute.jsx";
import history from './history';
import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
import { Provider } from 'react-redux';
import { lazy, Suspense  } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from 'store/index.jsx';
import LoadingAlert from 'components/CustomSweetAlert/LoadingAlert.jsx'; 

import Login from 'views/Login/Login.jsx';

//Student
const Student                             = lazy( () => import( 'views/Student/Index.jsx') );
const NewStudent                          = lazy( () => import( 'views/Student/New/New.jsx') );
const ShowStudent                         = lazy( () => import( 'views/Student/Show/Show.jsx') );
const EditStudent                         = lazy( () => import( 'views/Student/Edit/Edit.jsx') );
const PreEvaluationStudent                = lazy( () => import( 'views/Student/PreEvaluation/New.jsx') );
const PostEvaluationStudent               = lazy( () => import( 'views/Student/PostEvaluation/New.jsx') );
const PersonalEvaluationStudent           = lazy( () => import( 'views/Reports/Options/Participants/Evaluation/New.jsx') );
const StudentChangePassword               = lazy( () => import( 'views/Student/ChangePassword/ChangePassword.jsx') );
const SuccessStory                        = lazy( () => import( 'views/History/Index.jsx') );
const StudentGroupChange                  = lazy( () => import( 'views/Student/GroupChange/Index.jsx') );
const StudentGroupConfirmation            = lazy( () => import( 'views/Student/GroupChange/Confirm.jsx') );

//general
const ReactTable                          = lazy( () => import( "views/Tables/ReactTables.jsx") );
const AuthLayout                          = lazy( () => import( "layouts/Auth.jsx") );
const RtlLayout                           = lazy( () => import( "layouts/RTL.jsx") );

//reports
const Reports                             = lazy( () => import( 'views/Reports/Index.jsx') );
const ReportsAmbassadorStatistics         = lazy( () => import( 'views/Reports/Ambassador/Index.jsx') );
const ReportsOptions                      = lazy( () => import( 'views/Reports/Options/Index.jsx') );
const ReportsOptionsGroups                = lazy( () => import( 'views/Reports/Options/Groups/ShowGroups.jsx') );
const ReportsOptionsParticipants          = lazy( () => import( 'views/Reports/Options/Participants/ShowParticipants.jsx') );
const ReportsOptionsHistory               = lazy( () => import( 'views/Reports/Options/History/ShowHistory.jsx') );
const ReportsOptionsGraduates             = lazy( () => import( 'views/Reports/Options/Graduates/ShowGraduates.jsx') );
const ReportsOptionsNsaGraduates          = lazy( () => import( 'views/Reports/Options/NsaGraduates/ShowNsaGraduates.jsx') );
const ReportsOptionsEvaluation            = lazy( () => import( 'views/Reports/Options/Evaluation/ShowEvaluation.jsx') );
const ReportsGraduates                    = lazy( () => import( 'views/Reports/Graduates/Index.jsx') );

//Login
const Age                                 = lazy( () => import( 'views/Age/Age.jsx') );
const App                                 = lazy( () => import( 'views/Age/App.jsx') );

//Group
const Group                               = lazy( () => import( 'views/Group/Index.jsx') );
const ShowGroup                           = lazy( () => import( 'views/Group/Show/Show.jsx') );
const EditGroup                           = lazy( () => import( 'views/Group/Edit/Edit.jsx') );
const NewGroup                            = lazy( () => import( 'views/Group/New/New.jsx') );
const GroupAmbassadors                    = lazy( () => import( 'views/Group/New/ListAmbassador/Index.jsx') );
const ProjectProgress                     = lazy( () => import( 'views/Group/Progress/Index.jsx') );
const UploadImage                         = lazy( () => import( 'views/Group/Participants/Index.jsx') );
const StudentGroup                        = lazy( () => import( 'views/Group/Participants/Index.jsx') );

//Course
const ListCourse                          = lazy( () => import( 'views/Course/Index.jsx') );
const NewCourse                           = lazy( () => import( 'views/Course/Course/New/New.jsx') );
const ShowCourse                          = lazy( () => import( 'views/Course/Course/IndexCourse.jsx') );
const EditCourse                          = lazy( () => import( 'views/Course/Course/Edit/Edit.jsx') );
const TransferStudents                    = lazy( () => import ( 'views/Course/Course/Edit/Transfer/Index.jsx' ) );
const TransferStudentsConfirmation        = lazy( () => import ( 'views/Course/Course/Edit/Transfer/Confirmation/Confirm.jsx' ) );
const CloneCourse                         = lazy( () => import( 'views/Course/Course/Clone/Clone.jsx') );
const NewUnit                             = lazy( () => import( "views/Course/Unit/New/New.jsx") );
const ShowUnit                            = lazy( () => import( 'views/Course/Unit/Show/Show.jsx') );
const EditUnit                            = lazy( () => import( 'views/Course/Unit/Edit/Edit.jsx') );
const CloneUnit                           = lazy( () => import( 'views/Course/Unit/Clone/Clone.jsx') );
const NewPrinciple                        = lazy( () => import( "views/Course/Principle/New/New.jsx") );
const ShowPrinciple                       = lazy( () => import( 'views/Course/Principle/Show/Show.jsx') );
const EditPrinciple                       = lazy( () => import( 'views/Course/Principle/Edit/Edit.jsx') );
const ClonePrinciple                      = lazy( () => import( 'views/Course/Principle/Clone/Clone.jsx') );
const EditStop                            = lazy( () => import( 'views/Course/Principle/Stop/Edit/Edit.jsx') );
const ListAnswer                          = lazy( () => import( 'views/Course/Participant/Answer/Index.jsx') );
const AmbassadorParticipantsAnswer         = lazy( () => import( 'views/Course/Participant/AmbassadorParticipantsAnswer/Index.jsx') );

//Programmbs
const ProgramMbs                          = lazy( () => import( 'views/Programmbs/Show/Show.jsx') );
const NewProgramMbs                       = lazy( () => import( 'views/Programmbs/New/Show.jsx') );
const NewPlanProgramMbs                   = lazy( () => import( 'views/Programmbs/New/NewPlan.jsx') );
const NewProductProgramMbs                = lazy( () => import( 'views/Programmbs/New/NewProduct.jsx') );
const NewProcessProgramMbs                = lazy( () => import( 'views/Programmbs/New/NewProcess.jsx') );
const NewPriceProgramMbs                  = lazy( () => import( 'views/Programmbs/New/NewPrice.jsx') );
const NewPromotionProgramMbs              = lazy( () => import( 'views/Programmbs/New/NewPromotion.jsx') );
const NewPaperworkProgramMbs              = lazy( () => import( 'views/Programmbs/New/NewPaperwork.jsx') );
const NewQualityProgramMbs                = lazy( () => import( 'views/Programmbs/New/NewQuality.jsx') );
const NewServiceProgramMbs                = lazy( () => import( 'views/Programmbs/New/NewService.jsx') );
const NewHistoryProgramMbs                = lazy( () => import( 'views/Programmbs/New/NewHistory.jsx') );
const ShowPlanProgramMbs                  = lazy( () => import( 'views/Programmbs/Show/ShowPlan.jsx') );
const ShowProductProgramMbs               = lazy( () => import( 'views/Programmbs/Show/ShowProduct.jsx') );
const ShowProcessProgramMbs               = lazy( () => import( 'views/Programmbs/Show/ShowProcess.jsx') );
const ShowPriceProgramMbs                 = lazy( () => import( 'views/Programmbs/Show/ShowPrice.jsx') );
const ShowPromotionProgramMbs             = lazy( () => import( 'views/Programmbs/Show/ShowPromotion.jsx') );
const ShowPaperworkProgramMbs             = lazy( () => import( 'views/Programmbs/Show/ShowPaperwork.jsx') );
const ShowQualityProgramMbs               = lazy( () => import( 'views/Programmbs/Show/ShowQuality.jsx') );
const ShowServiceProgramMbs               = lazy( () => import( 'views/Programmbs/Show/ShowService.jsx') );
const ShowHistoryProgramMbs               = lazy( () => import( 'views/History/Show/Index.jsx') );
const NewHistory                          = lazy( () => import( 'views/History/New/New.jsx') );
const EditHistory                         = lazy( () => import( 'views/History/Edit/Edit.jsx') );
const EditProgramMbs                      = lazy( () => import( 'views/Programmbs/Edit/Show.jsx') );
const EditPlanProgramMbs                  = lazy( () => import( 'views/Programmbs/Edit/EditPlan.jsx') );
const EditProductProgramMbs               = lazy( () => import( 'views/Programmbs/Edit/EditProduct.jsx') );
const EditProcessProgramMbs               = lazy( () => import( 'views/Programmbs/Edit/EditProcess.jsx') );
const EditPriceProgramMbs                 = lazy( () => import( 'views/Programmbs/Edit/EditPrice.jsx') );
const EditPromotionProgramMbs             = lazy( () => import( 'views/Programmbs/Edit/EditPromotion.jsx') );
const EditPaperworkProgramMbs             = lazy( () => import( 'views/Programmbs/Edit/EditPaperwork.jsx') );
const EditQualityProgramMbs               = lazy( () => import( 'views/Programmbs/Edit/EditQuality.jsx') );
const EditServiceProgramMbs               = lazy( () => import( 'views/Programmbs/Edit/EditService.jsx') );
const EditHistoryProgramMbs               = lazy( () => import( 'views/Programmbs/Edit/EditHistory.jsx') );
const ProgramMbsFile                      = lazy( () => import( 'views/Programmbs/ShowFile/Show.jsx') );
const Project                             = lazy( () => import( 'views/Project/Index.jsx') );
const HistoryTabs                         = lazy( () => import( 'views/History/HistoryTabs/Index.jsx' ));

//Programsa
const ProgramSa                           = lazy( () => import( 'views/Programsa/Show/Show.jsx') );
const NewProgramSa                        = lazy( () => import( 'views/Programsa/New/Show.jsx') );
const EditProgramSa                       = lazy( () => import( 'views/Programsa/Edit/Show.jsx') );
const EditMisionProgramSa                 = lazy( () => import( 'views/Programsa/Edit/EditMision.jsx') );
const EditGenerateProgramSa               = lazy( () => import( 'views/Programsa/Edit/EditGenerate.jsx') );
const EditFacilitateProgramSa             = lazy( () => import( 'views/Programsa/Edit/EditFacilitate.jsx') );
const EditGraduateProgramSa               = lazy( () => import( 'views/Programsa/Edit/EditGraduate.jsx') );
const EditSupportProgramSa                = lazy( () => import( 'views/Programsa/Edit/EditSupport.jsx') );
const EditPilotProgramSa                  = lazy( () => import( 'views/Programsa/Edit/EditPilot.jsx') );
const ProgramSaFile                       = lazy( () => import( 'views/Programsa/ShowFile/Show.jsx') );

//user
const UserEdit                            = lazy( () => import( 'views/User/Edit/Edit.jsx') );
const UserChangePassword                  = lazy( () => import( 'views/User/NewPassword/ChangePassword/ChangePassword.jsx') );
const newPassword                         = lazy( () => import( 'views/User/NewPassword/ChangePassword.jsx') );
const UserNewPassword                     = lazy( () => import( 'views/User/NewPassword/ChangePassword/ChangePassword.jsx') );
const EmailAccess                         = lazy( () => import( 'views/User/NewPassword/EmailAccessVerification/Index.jsx') );

//AccountVerification
const ProfileStep                         = lazy( () => import( 'views/User/AccountVerification/Steps/ProfileStep.jsx') );
const EmailStep                           = lazy( () => import( 'views/User/AccountVerification/Steps/EmailStep.jsx') );
const WhatsappStep                        = lazy( () => import( 'views/User/AccountVerification/Steps/WhatsappStep.jsx') );
const PhoneStep                           = lazy( () => import( 'views/User/AccountVerification/Steps/PhoneStep.jsx') );
const RevisionStep                        = lazy( () => import( 'views/User/AccountVerification/Steps/RevisionStep.jsx') );

//Profile
const Profile                             = lazy( () => import( 'views/Profile/Show/Show.jsx') );
const ProfileEdit                         = lazy( () => import( 'views/Profile/Edit/Edit.jsx') );
const ProfileEditPassword                 = lazy( () => import( 'views/Profile/ChangePassword/ChangePassword.jsx') );

//certificate
const CertificateGroup                    = lazy( () => import( 'views/Certificate/Group/Index.jsx') );
const CertificateList                     = lazy( () => import( 'views/Certificate/Student/Index.jsx') );

//Dashboard
const Dashboard                           = lazy( () => import( 'views/Dashboard/Index.jsx') );
const DashboardStudent                    = lazy( () => import( 'views/Dashboard/Student/Index.jsx') );
const AssignMentor                        = lazy( () => import( 'views/Dashboard/AssignMentor/Index.jsx') );
const ConfirmMentor                       = lazy( () => import( 'views/Dashboard/AssignMentor/Confirm.jsx') );
const ClearPendingDashboard               = lazy( () => import( 'views/Dashboard/AssignMentor/Clear.jsx') );
const CourseContent                       = lazy( () => import( 'views/Dashboard/Course/Content/Index.jsx') );
const PrincipleContent                    = lazy( () => import( 'views/Dashboard/Course/PrinciplesContent/Show.jsx') );
const CourseList                          = lazy( () => import( 'views/Dashboard/Course/CourseList/show.jsx') );
const StudentAnswers                      = lazy( () => import( 'views/Dashboard/Course/Answer/Index.jsx') );
const PrincipleComment                    = lazy( () => import( 'views/Dashboard/Course/PrincipleComment/Index.jsx') );
const RequirementCorrectionsMbs           = lazy( () => import( 'views/Dashboard/Corrections/RequirementCorrections/Programmbs/Index.jsx') );
const RequirementCorrectionsSa            = lazy( () => import( 'views/Dashboard/Corrections/RequirementCorrections/Programsa/Index.jsx') );
const RequirementCorrectionsWellness      = lazy( () => import( 'views/Dashboard/Corrections/RequirementCorrections/Wellness/Index.jsx') );

const NsaGraduates                        = lazy( () => import( 'views/NsaGraduates/Index.jsx') );
const NsaGraduatesGroups                  = lazy( () => import( 'views/NsaGraduates/Groups/Index.jsx') );
const NsaGroupParticipants                = lazy( () => import( 'views/NsaGraduates/GroupParticipants/Index.jsx') );

//code
const ListCode                            = lazy( () => import( 'views/Code/Index.jsx') );
const PartnerShowCode                     = lazy( () => import( 'views/Code/Partner/Show/Show.jsx') );
const PartnerEditCode                     = lazy( () => import( 'views/Code/Partner/Edit/Edit.jsx') );
const PartnerNewCode                      = lazy( () => import( 'views/Code/Partner/New/New.jsx') );
const WellCompletionShowCode              = lazy( () => import( 'views/Code/WellCompletion/Show/Show.jsx') );
const WellCompletionEditCode              = lazy( () => import( 'views/Code/WellCompletion/Edit/Edit.jsx') );
const WellCompletionNewCode               = lazy( () => import( 'views/Code/WellCompletion/New/New.jsx') );
const LitCompletionShowCode               = lazy( () => import( 'views/Code/LitCompletion/Show/Show.jsx') );
const LitCompletionEditCode               = lazy( () => import( 'views/Code/LitCompletion/Edit/Edit.jsx') );
const LitCompletionNewCode                = lazy( () => import( 'views/Code/LitCompletion/New/New.jsx') );
const SibCompletionShowCode               = lazy( () => import( 'views/Code/SibCompletion/Show/Show.jsx') );
const SibCompletionEditCode               = lazy( () => import( 'views/Code/SibCompletion/Edit/Edit.jsx') );
const SibCompletionNewCode                = lazy( () => import( 'views/Code/SibCompletion/New/New.jsx') );

//Administrator
const ListAdmin                           = lazy( () => import( 'views/Administrator/Index.jsx') );
const NewAdmin                            = lazy( () => import( 'views/Administrator/New/New.jsx') );
const ShowAdmin                           = lazy( () => import( 'views/Administrator/Show/Show.jsx') );
const EditAdmin                           = lazy( () => import( 'views/Administrator/Edit/Edit.jsx') );
const DeleteAdmin                         = lazy( () => import( 'views/Administrator/Delete/Index.jsx') );
const AssignAdmin                         = lazy( () => import( 'views/Administrator/Delete/Assing/Confirm.jsx') );
const AdminPassword                       = lazy( () => import( 'views/Administrator/ChangePassword/ChangePassword.jsx') );

//Transfer
const NewTransfer                         = lazy( () => import( 'views/Transfer/New/New.jsx') );
const ShowTransfer                        = lazy( () => import( 'views/Transfer/Show/Show.jsx') );
const EditTransfer                        = lazy( () => import( 'views/Transfer/Edit/Edit.jsx') );

//Register
const Register                            = lazy( () => import( 'views/Register/Index.jsx') );
const RegisterStudent                     = lazy( () => import( 'views/Register/Participants/New.jsx') );
const RegisterEvaluation                  = lazy( () => import( 'views/Register/PreEvaluation/New.jsx') );
const WaitingPage                         = lazy( () => import( 'views/Register/Participants/WaitingPage.jsx') );

//Contract
const Contract                            = lazy( () => import( 'views/Contract/Index.jsx') );
const AdminContract                       = lazy( () => import( 'views/Contract/Admin/Index.jsx') );
const AdminContractWizard                 = lazy( () => import( 'views/Contract/Admin/Wizard/Index.jsx') );

//Grant
const Grant                               = lazy( () => import( 'views/Grant/Index.jsx') );
const ShowGrant                           = lazy( () => import( 'views/Grant/Show/Show.jsx') );
const InfoGrant                           = lazy( () => import( 'views/GrantAmbassador/Info/Info.jsx') );
const EditGrant                           = lazy( () => import( 'views/Grant/Edit/Edit.jsx') );
const NewGrant                            = lazy( () => import( 'views/Grant/New/New.jsx') );
const UpdateGrant                         = lazy( () => import( 'views/Grant/Update/Show.jsx') );
const GrantAmbassador                     = lazy( () => import( 'views/GrantAmbassador/Index.jsx') );
const NewGrantAmbassador                  = lazy( () => import( 'views/GrantAmbassador/New/New.jsx') );
const EditGrantAmbassador                 = lazy( () => import( 'views/GrantAmbassador/Edit/Edit.jsx') );
const ShowGrantAmbassador                 = lazy( () => import( 'views/GrantAmbassador/Show/Show.jsx') );
const ApplicationGrantAmbassador          = lazy( () => import( 'views/Grant/Application/Index.jsx') );
const ShowGrantStatisticGroup             = lazy( () => import( 'views/Grant/IndexTabs/StatisticTab/Groups/Index.jsx') );
const GrantRequirement                    = lazy( () => import( 'views/GrantRequirement/Index.jsx') );
const NewCustomGrantRequirement           = lazy( () => import( 'views/GrantRequirement/Custom/New/New.jsx') );
const EditCustomGrantRequirement          = lazy( () => import( 'views/GrantRequirement/Custom/Edit/Edit.jsx') );
const ShowCustomGrantRequirement          = lazy( () => import( 'views/GrantRequirement/Custom/Show/Show.jsx') );
const NewDefaultGrantRequirement          = lazy( () => import( 'views/GrantRequirement/Default/New/New.jsx') );
const EditDefaultGrantRequirement         = lazy( () => import( 'views/GrantRequirement/Default/Edit/Edit.jsx') );
const ShowDefaultGrantRequirement         = lazy( () => import( 'views/GrantRequirement/Default/Show/Show.jsx') );

// Wellness Requirement
const ShowWellness                        = lazy( () => import( 'views/Requirement/Wellness/Show/Index.jsx') );
const NewWellness                         = lazy( () => import( 'views/Requirement/Wellness/New/Index.jsx') );
const EditWellness                        = lazy( () => import( 'views/Requirement/Wellness/Edit/Index.jsx') );
const WellnessFile                      = lazy( () => import( 'views/Requirement/Wellness/ShowFile/Show.jsx') );

//Sa Requirement
const ShowProgramSa                       = lazy( () => import( 'views/Requirement/SuccessAmbassador/Show/Show.jsx') );
const NewProgramSaReq                     = lazy( () => import( 'views/Requirement/SuccessAmbassador/New/Index.jsx') );
const EditProgramSaReq                    = lazy( () => import( 'views/Requirement/SuccessAmbassador/Edit/Index.jsx') );

// Mbs Requirement
const ShowMbs                             = lazy( () => import( 'views/Requirement/Mbs/Show/Index.jsx') );
const NewMbs                              = lazy( () => import( 'views/Requirement/Mbs/New/Index.jsx') );
const EditMbs                             = lazy( () => import( 'views/Requirement/Mbs/Edit/Index.jsx') );

// Express Requirement
const ShowExpress                         = lazy( () => import( 'views/Requirement/Express/Show/Index.jsx') );
const NewExpress                          = lazy( () => import( 'views/Requirement/Express/New/Index.jsx') );
const EditExpress                         = lazy( () => import( 'views/Requirement/Express/Edit/Index.jsx') );
const ExpressFile                         = lazy( () => import( 'views/Requirement/Express/ShowFile/Show.jsx' ));

//Resources
const Resource                            = lazy( () => import( 'views/Resource/Index.jsx') );
const NewResource                         = lazy( () => import( 'views/Resource/Resource/New/New.jsx') );
const EditResource                        = lazy( () => import( 'views/Resource/Resource/Edit/Edit.jsx') );
const ShowResource                        = lazy( () => import( 'views/Resource/Resource/Show/Show.jsx') );
const Category                            = lazy( () => import( 'views/Resource/Category/Index.jsx') );
const NewCategory                         = lazy( () => import( 'views/Resource/Category/New/New.jsx') );
const EditCategory                        = lazy( () => import( 'views/Resource/Category/Edit/Edit.jsx') );
const ShowCategory                        = lazy( () => import( 'views/Resource/Category/Show/Show.jsx') );
const Subcategory                         = lazy( () => import( 'views/Resource/Subcategory/Index.jsx') );
const NewSubcategory                      = lazy( () => import( 'views/Resource/Subcategory/New/New.jsx') );
const EditSubcategory                     = lazy( () => import( 'views/Resource/Subcategory/Edit/Edit.jsx') );
const ShowSubcategory                     = lazy( () => import( 'views/Resource/Subcategory/Show/Show.jsx') );
const ListCategory                        = lazy( () => import( 'views/Resource/Ambassador/Categoty/show.jsx') );
const ListSubcategory                     = lazy( () => import( 'views/Resource/Ambassador/Subcategory/show.jsx') );
const ListResource                        = lazy( () => import( 'views/Resource/Ambassador/Resource/show.jsx') );

//Translation
const TranslationLanguages                = lazy( () => import( 'views/Translation/Index.jsx') );
const TranslationLanguagesNew             = lazy( () => import( 'views/Translation/New/New.jsx') );
const TranslationLanguagesEdit            = lazy( () => import( 'views/Translation/Edit/Edit.jsx') );

//Evaluation
const NewPreevaluation                    = lazy( () => import( 'views/Evaluation/PreEvaluation/New.jsx') );
const NewWellPreevaluation                = lazy( () => import( 'views/Evaluation/WellPreEvaluation/New.jsx') );
const FollowUpEvaluation                 = lazy( () => import( 'views/Evaluation/Mbs/Index.jsx' ));

//Introduction 
const Introduction                        = lazy( () => import( 'views/Group/Introduction/Index.jsx') );
const IntroductionGroupList               = lazy( () => import( 'views/Group/Introduction/GroupList/Index.jsx') );
const ShowIntroduction                    = lazy( () => import( 'views/Group/Introduction/Show/Show.jsx') );
const NewIntroduction                     = lazy( () => import( 'views/Group/Introduction/New/New.jsx') );
const EditIntroduction                    = lazy( () => import( 'views/Group/Introduction/Edit/Edit.jsx') );

//Section
const Tutorial                            = lazy( () => import( 'views/Tutorial/Index.jsx') );
const NewTutorial                         = lazy( () => import( 'views/Tutorial/New/New.jsx') );
const ShowTutorial                        = lazy( () => import( 'views/Tutorial/Show/Show.jsx') );
const EditTutorial                        = lazy( () => import( 'views/Tutorial/Edit/Edit.jsx') );
const Section                             = lazy( () => import( 'views/Tutorial/Section/Index.jsx') );
const NewSection                          = lazy( () => import( 'views/Tutorial/Section/New/New.jsx') );
const ShowSection                         = lazy( () => import( 'views/Tutorial/Section/Show/Show.jsx') );
const EditSection                         = lazy( () => import( 'views/Tutorial/Section/Edit/Edit.jsx') );
const ListSection                         = lazy( () => import( 'views/Tutorial/User/Section/Index.jsx') );
const UserTutorial                        = lazy( () => import( 'views/Tutorial/User/Tutorial/Index.jsx') );

//Checkout
const Checkout                            = lazy( () => import( 'views/Checkout/Index.jsx') );
const SuccessPayment                      = lazy( () => import( 'views/Checkout/Success/Index.jsx') );

//Payment
const PaymentList                         = lazy( () => import( 'views/Payment/Index.jsx') );
const ShowPayment                         = lazy( () => import( 'views/Payment/Show/Show.jsx') );


//Ambassador
const Ambassador                        	= lazy( () => import( 'views/Ambassador/Index.jsx' ) );
const ShowAmbassador                    	= lazy( () => import( 'views/Ambassador/Show/Show.jsx' ) );
const EditAmbassador                    	= lazy( () => import( 'views/Ambassador/Edit/Edit.jsx' ) );
const NewAmbassador                     	= lazy( () => import( 'views/Ambassador/New/New.jsx' ) );
const AmbassadorChangePassword          	= lazy( () => import( 'views/Ambassador/ChangePassword/ChangePassword.jsx' ) );
const AmbassadorStudent                 	= lazy( () => import( 'views/AmbassadorStudent/Index.jsx' ) );
const AmbassadorStudentAssignMentor     	= lazy( () => import( 'views/AmbassadorStudent/AssignMentor/Index.jsx' ) );
const AmbassadorStudentConfirmMentor    	= lazy( () => import( 'views/AmbassadorStudent/AssignMentor/Confirm.jsx' ) );
const AmbassadorGallery                   = lazy( () => import( 'views/Ambassador/Gallery/Index.jsx' ));

//Tracking
const TrackingList                        = lazy( () => import( 'views/Tracking/Index.jsx' ) );
const ShowTracking                        = lazy( () => import( 'views/Tracking/Show/Show.jsx' ) );

//Recognition
const RecognitionList                     = lazy(() => import('views/Recognition/List/Show.jsx'));

//Welcome Package
const WelcomePackage                      = lazy( () => import( 'views/WelcomePackage/Index.jsx'));
const WellnessWelcomePackage                      = lazy( () => import( 'views/WelcomePackage/Wellness/Index.jsx'));

//Progress Control
const ProgressControlMatching             = lazy( () => import( 'views/ProgressControl/Matching/Index.jsx' ));
const ProgressControlCourses              = lazy( () => import( 'views/ProgressControl/Index.jsx' ));

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Suspense fallback={<LoadingAlert/>}>
          <Switch>
            <PrivateRoute path="/rtl" component={RtlLayout} />
            <PrivateRoute path="/auth" component={AuthLayout} />
            <PrivateRoute path="/table" component={ReactTable} />
            <PrivateRoute path="/age" component={Age} />
            <Route path="/login" component={Login} exact />
            <Route path="/login/:lang" component={Login} exact />
            <PrivateRoute path="/app" component={App} />
            <PrivateRoute path="/student" component={Student} exact />
            <PrivateRoute path="/student/new/:id" component={NewStudent} exact />
            <PrivateRoute path="/student/show/:id" component={ShowStudent} exact />
            <PrivateRoute path="/student/edit/:id" component={EditStudent} exact />
            <PrivateRoute path="/student/preevaluation" component={PreEvaluationStudent} exact />
            <PrivateRoute path="/student/postevaluation" component={PostEvaluationStudent} exact />
            <PrivateRoute path="/student/personalevaluation/:id" component={PersonalEvaluationStudent} exact />
            <PrivateRoute path="/student/editpassword/:id" component={StudentChangePassword} exact />
            <PrivateRoute path="/student/groupchange/:student" component={StudentGroupChange} exact />
            <PrivateRoute path="/student/groupconfirmation/:student/:group" component={StudentGroupConfirmation} exact />
            <PrivateRoute path="/programmbs/new" component={NewProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/plan" component={NewPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/product" component={NewProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/process" component={NewProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/price" component={NewPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/promotion" component={NewPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/paperwork" component={NewPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/quality" component={NewQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/service" component={NewServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/history" component={NewHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/:id" component={ProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/plan/:id" component={ShowPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/product/:id" component={ShowProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/process/:id" component={ShowProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/price/:id" component={ShowPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/promotion/:id" component={ShowPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/paperwork/:id" component={ShowPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/quality/:id" component={ShowQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/service/:id" component={ShowServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/history/:id" component={ShowHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/:page/:id" component={ProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/:id" component={EditProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/plan/:id" component={EditPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/product/:id" component={EditProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/process/:id" component={EditProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/price/:id" component={EditPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/promotion/:id" component={EditPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/paperwork/:id" component={EditPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/quality/:id" component={EditQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/service/:id" component={EditServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/history/:id" component={EditHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/showfile/:id" component={ProgramMbsFile} exact />
            <PrivateRoute path="/project" component={Project} exact />
            <PrivateRoute path="/programsa/show/:id" component={ProgramSa} exact />
            <PrivateRoute path="/programsa/new" component={NewProgramSa} exact />
            <PrivateRoute path="/programsa/edit/:id" component={EditProgramSa} exact />
            <PrivateRoute path="/programsa/edit/mision/:id" component={EditMisionProgramSa} exact />
            <PrivateRoute path="/programsa/edit/generate/:id" component={EditGenerateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/facilitate/:id" component={EditFacilitateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/graduate/:id" component={EditGraduateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/support/:id" component={EditSupportProgramSa} exact />
            <PrivateRoute path="/programsa/edit/pilot/:id" component={EditPilotProgramSa} exact />
            <PrivateRoute path="/programsa/showfile/:id" component={ProgramSaFile} exact />
            <PrivateRoute path="/user/newpassword/:id" component={UserNewPassword} exact />
            <PrivateRoute path="/user/edit/:id" component={UserEdit} exact />
            <Route path="/user/editpassword/:id" component={UserChangePassword} exact />
            <PrivateRoute path="/user/accountverification/steps/profile" component={ProfileStep} exact />
            <PrivateRoute path="/user/accountverification/steps/email" component={EmailStep} exact />
            <PrivateRoute path="/user/accountverification/steps/whatsapp" component={WhatsappStep} exact />
            <PrivateRoute path="/user/accountverification/steps/phone" component={PhoneStep} exact />
            <PrivateRoute path="/user/accountverification/steps/revision" component={RevisionStep} exact />
            <PrivateRoute path="/profile" component={Profile} exact />
            <PrivateRoute path="/profile/edit" component={ProfileEdit} exact />
            <PrivateRoute path="/profile/editpassword" component={ProfileEditPassword} exact />
            <PrivateRoute path="/group" component={Group} exact />
            <PrivateRoute path="/group/new/:id" component={NewGroup} exact />
            <PrivateRoute path="/group/show/:id" component={ShowGroup} exact />
            <PrivateRoute path="/group/edit/:id" component={EditGroup} exact />
            <PrivateRoute path="/group/ambassador" component={GroupAmbassadors} exact />
            <PrivateRoute path="/group/progress/:id" component={ProjectProgress} exact />
            <PrivateRoute path="/group/student/:id" component={StudentGroup} exact />
            <PrivateRoute path="/group/uploadImage/:id" component={UploadImage} exact />
            <PrivateRoute path="/group/introduction" component={Introduction} exact />
            <PrivateRoute path="/group/introduction/grouplist" component={IntroductionGroupList} exact />
            <PrivateRoute path="/group/introduction/new/:id" component={NewIntroduction} exact />
            <PrivateRoute path="/group/introduction/edit/:id" component={EditIntroduction} exact />
            <PrivateRoute path="/group/introduction/show/:id" component={ShowIntroduction} exact />
            <PrivateRoute path="/ambassadorstudent" component={AmbassadorStudent} exact />
            <PrivateRoute path="/ambassador" component={Ambassador} exact />
            <PrivateRoute path="/ambassador/show/:id" component={ShowAmbassador} exact />
            <PrivateRoute path="/ambassador/edit/:id" component={EditAmbassador} exact />
            <PrivateRoute path="/ambassador/new" component={NewAmbassador} exact />
            <PrivateRoute path="/ambassador/editpassword/:id" component={AmbassadorChangePassword} exact />
            <Route path="/ambassador/gallery/:country" component={AmbassadorGallery} exact />
            <PrivateRoute path="/certificate" component={CertificateGroup} exact />
            <PrivateRoute path="/certificate/list/student/:id" component={CertificateList} exact />
            <PrivateRoute path="/dashboard" component={Dashboard} exact />
            <PrivateRoute path="/dashboard/student" component={DashboardStudent} exact />
            <PrivateRoute path="/dashboard/assignmentor/:student" component={AssignMentor} exact />
            <PrivateRoute path="/dashboard/confirmmentor/:student/:group" component={ConfirmMentor} exact />
            <PrivateRoute path="/dashboard/clearpending/:student" component={ClearPendingDashboard} exact />
            <PrivateRoute path="/dashboard/corrections/requirementcorrections/programmbs/:id" component={RequirementCorrectionsMbs} exact />
            <PrivateRoute path="/dashboard/corrections/requirementcorrections/programsa/:id" component={RequirementCorrectionsSa} exact />
            <PrivateRoute path="/dashboard/corrections/requirementcorrections/wellness/:id" component={RequirementCorrectionsWellness} exact />

            <PrivateRoute path="/nsagraduates/:id" component={NsaGraduates} exact />
            <PrivateRoute path="/nsagraduates/groups/:id" component={NsaGraduatesGroups} exact />
            <PrivateRoute path="/nsagraduates/groupparticipants/:group/:user" component={NsaGroupParticipants} exact />

            <PrivateRoute path="/ambassadorstudent/assignmentor/:student" component={AmbassadorStudentAssignMentor} exact />
            <PrivateRoute path="/ambassadorstudent/confirmmentor/:student/:group" component={AmbassadorStudentConfirmMentor} exact />
            <PrivateRoute path="/code" component={ListCode} exact />
            <PrivateRoute path="/code/partner/show/:id" component={PartnerShowCode} exact />
            <PrivateRoute path="/code/partner/edit/:id" component={PartnerEditCode} exact />
            <PrivateRoute path="/code/partner/new" component={PartnerNewCode} exact />
            <PrivateRoute path="/code/wellcompletion/show/:id" component={WellCompletionShowCode} exact />
            <PrivateRoute path="/code/wellcompletion/edit/:id" component={WellCompletionEditCode} exact />
            <PrivateRoute path="/code/wellcompletion/new" component={WellCompletionNewCode} exact />
            <PrivateRoute path="/code/litcompletion/show/:id" component={LitCompletionShowCode} exact />
            <PrivateRoute path="/code/litcompletion/edit/:id" component={LitCompletionEditCode} exact />
            <PrivateRoute path="/code/litcompletion/new" component={LitCompletionNewCode} exact />
            <PrivateRoute path="/code/sibcompletion/show/:id" component={SibCompletionShowCode} exact />
            <PrivateRoute path="/code/sibcompletion/edit/:id" component={SibCompletionEditCode} exact />
            <PrivateRoute path="/code/sibcompletion/new" component={SibCompletionNewCode} exact />
            <PrivateRoute path="/course" component={ListCourse} exact />
            <PrivateRoute path="/course/new" component={NewCourse} exact />
            <PrivateRoute path="/course/show/:id" component={ShowCourse} exact />
            <PrivateRoute path="/course/edit/:id" component={EditCourse} exact />
            <PrivateRoute path="/course/edit/transfer/:id" component={TransferStudents} exact />
            <PrivateRoute path="/course/edit/transfer/confirmation/:initial/:final" component={TransferStudentsConfirmation} exact />
            <PrivateRoute path="/course/clone/:id" component={CloneCourse} exact />
            <PrivateRoute path="/course/content/:id" component={CourseContent} exact />

            <PrivateRoute path="/course/requirement/mbs/show/:course/:student" component={ShowMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/business/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/qualitylife/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/moneymanagement/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomestatement/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomeexpenselog/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/businessbudget/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/successstory/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/checklist/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/postevaluation/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/service/new/:tab" component={NewMbs} exact />
            
            <PrivateRoute path="/course/requirement/express/show/:course/:student" component={ShowExpress} exact />
            <PrivateRoute path="/course/requirement/express/new/:tab" component={NewExpress} exact />
            <PrivateRoute path="/course/requirement/express/edit/:tab/:id" component={EditExpress} exact />
            <PrivateRoute path="/requirement/express/showfile/:id" component={ExpressFile} exact />
            
            <PrivateRoute path="/course/requirement/mbs/business/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/qualitylife/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/moneymanagement/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomestatement/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomeexpenselog/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/businessbudget/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/successstory/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/checklist/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/postevaluation/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/service/edit/:tab/:id" component={EditMbs} exact />

            <PrivateRoute path="/course/requirement/wellness/show/:course/:student" component={ShowWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/ambassadorskills/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/pilotgroup/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/futureplans/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/conclusions/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/postevaluation/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/ambassadorskills/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/pilotgroup/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/futureplans/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/conclusions/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/postevaluation/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/requirement/wellness/showfile/:id" component={WellnessFile} exact />
            <PrivateRoute path="/course/participant/answer/:course/:student" component={ListAnswer} exact />
            <PrivateRoute path="/course/participant/ambassadorparticipantsanswer/:course/:student" component={AmbassadorParticipantsAnswer} exact />
            <PrivateRoute path="/dashboard/course/answer/:course/:student" component={StudentAnswers} exact />
            <PrivateRoute path="/dashboard/course/principlecomment/:id" component={PrincipleComment} exact />

            <PrivateRoute path="/course/requirement/programsa/mission/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/generategroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/facilitegroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/graduategroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/supportgroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/pilotgroup/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/mission/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/generategroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/facilitegroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/graduategroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/supportgroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/pilotgroup/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/show/:course/:student" component={ShowProgramSa} exact />
            
            <PrivateRoute path="/course/list" component={CourseList} exact />
            <PrivateRoute path="/unit/new/:id" component={NewUnit} exact />
            <PrivateRoute path="/unit/show/:id" component={ShowUnit} exact />
            <PrivateRoute path="/unit/edit/:id" component={EditUnit} exact />
            <PrivateRoute path="/unit/clone/:id" component={CloneUnit} exact />
            <PrivateRoute path="/principle/new" component={NewPrinciple} exact />
            <PrivateRoute path="/principle/show/:id" component={ShowPrinciple} exact />
            <PrivateRoute path="/principle/edit/:id" component={EditPrinciple} exact />
            <PrivateRoute path="/principle/clone/:id" component={ClonePrinciple} exact />
            <PrivateRoute path="/principle/content/:id" component={PrincipleContent} />
            <PrivateRoute path="/principle/stop/edit/:id" component={EditStop} />
            <PrivateRoute path="/admin" component={ListAdmin} exact />
            <PrivateRoute path="/admin/new" component={NewAdmin} exact />
            <PrivateRoute path="/admin/show/:id" component={ShowAdmin} exact />
            <PrivateRoute path="/admin/edit/:id" component={EditAdmin} exact />
            <PrivateRoute path="/admin/editpassword/:id" component={AdminPassword} exact />
            <PrivateRoute path="/admin/delete/:id" component={DeleteAdmin} exact />
            <PrivateRoute path="/admin/delete/assign/:id/:admin" component={AssignAdmin} exact />
            <PrivateRoute path="/report" component={Reports} exact />
            <PrivateRoute path="/report/ambassadorstatistics" component={ReportsAmbassadorStatistics} exact />
            <PrivateRoute path="/report/options/:id" component={ReportsOptions} exact />
            <PrivateRoute path="/report/options/:id/groups" component={ReportsOptionsGroups} exact />
            <PrivateRoute path="/report/options/:id/participants" component={ReportsOptionsParticipants} exact />
            <PrivateRoute path="/report/options/:id/histories" component={ReportsOptionsHistory} exact />
            <PrivateRoute path="/report/options/:id/graduates" component={ReportsOptionsGraduates} exact />
            <PrivateRoute path="/report/options/:id/nsagraduates" component={ReportsOptionsNsaGraduates} exact />
            <PrivateRoute path="/report/options/:id/evaluation" component={ReportsOptionsEvaluation} exact />
            <PrivateRoute path="/report/graduates" component={ReportsGraduates} exact />
            <Route path="/register" component={Register} exact />
            <Route path="/register/:lang" component={Register} exact />
            <Route path="/register/new/:id" component={RegisterStudent} exact />
            <Route path="/register/participants/waitingpage" component={WaitingPage} exact />
            <PrivateRoute path="/registration/evaluation" component={RegisterEvaluation} exact />
            <PrivateRoute path="/student/successstory/list" component={SuccessStory} exact />
            <Route path="/password" component={newPassword} exact />
            <Route path="/password/emailaccessverification" component={EmailAccess} exact />
            <PrivateRoute path="/grant" component={Grant} exact />
            <PrivateRoute path="/grant/new" component={NewGrant} exact />
            <PrivateRoute path="/grant/show/:id" component={ShowGrant} exact />
            <PrivateRoute path="/grant/info/:id" component={InfoGrant} exact />
            <PrivateRoute path="/grant/edit/:id" component={EditGrant} exact />
            <PrivateRoute path="/grant/update/:id/:grant" component={UpdateGrant} exact />
            <PrivateRoute path="/grant/ambassador" component={GrantAmbassador} exact />
            <PrivateRoute path="/grant/newambassador/:id" component={NewGrantAmbassador} exact />
            <PrivateRoute path="/grant/editambassador/:id/:ambassador" component={EditGrantAmbassador} exact />
            <PrivateRoute path="/grant/showambassador/:id/:ambassador" component={ShowGrantAmbassador} exact />
            <PrivateRoute path="/grant/application/:id" component={ApplicationGrantAmbassador} exact />
            <PrivateRoute path="/grant/statistic/group/:id" component={ShowGrantStatisticGroup} exact />
            <PrivateRoute path="/transfer/show/:id" component={ShowTransfer} exact />
            <PrivateRoute path="/transfer/edit/:id" component={EditTransfer} exact />
            <PrivateRoute path="/transfer/new" component={NewTransfer} exact />
            <PrivateRoute path="/contract" component={Contract} exact />
            <PrivateRoute path="/contract/admin" component={AdminContract} exact />
            <PrivateRoute path="/contract/admin/wizard/:id/:language" component={AdminContractWizard} exact />
            <PrivateRoute path="/resource" component={Resource} exact />
            <PrivateRoute path="/resource/new" component={NewResource} exact />
            <PrivateRoute path="/resource/edit/:id" component={EditResource} exact />
            <PrivateRoute path="/resource/show/:id" component={ShowResource} exact />
            <PrivateRoute path="/resource/category" component={Category} exact />
            <PrivateRoute path="/resource/category/new" component={NewCategory} exact />
            <PrivateRoute path="/resource/category/edit/:id" component={EditCategory} exact />
            <PrivateRoute path="/resource/category/show/:id" component={ShowCategory} exact />
            <PrivateRoute path="/resource/subcategorylist/:id" component={Subcategory} exact />
            <PrivateRoute path="/resource/subcategory/new" component={NewSubcategory} exact />
            <PrivateRoute path="/resource/subcategory/edit/:id" component={EditSubcategory} exact />
            <PrivateRoute path="/resource/subcategory/show/:id" component={ShowSubcategory} exact />
            <PrivateRoute path="/resource/ambassador/category" component={ListCategory} exact />
            <PrivateRoute path="/resource/ambassador/subcategory/:id" component={ListSubcategory} exact />
            <PrivateRoute path="/resource/ambassador/resource/:id" component={ListResource} exact />
            <PrivateRoute path="/translation" component={TranslationLanguages} exact />
            <PrivateRoute path="/translation/new" component={TranslationLanguagesNew} exact />
            <PrivateRoute path="/translation/:id/new/:language" component={TranslationLanguagesNew} exact />
            <PrivateRoute path="/translation/edit/:id/:language" component={TranslationLanguagesEdit} exact />
            <PrivateRoute path="/evaluation/preevaluation/:id" component={NewPreevaluation} exact />
            <PrivateRoute path="/evaluation/wellpreevaluation/:id" component={NewWellPreevaluation} exact />
            <Route path="/evaluation/mbs/followup/:id" component={FollowUpEvaluation} exact />
            <PrivateRoute path="/tutorial" component={Tutorial} exact />
            <PrivateRoute path="/tutorial/new" component={NewTutorial} exact />
            <PrivateRoute path="/tutorial/show/:id" component={ShowTutorial} exact />
            <PrivateRoute path="/tutorial/edit/:id" component={EditTutorial} exact />
            <PrivateRoute path="/tutorial/section" component={Section} exact />
            <PrivateRoute path="/tutorial/section/new" component={NewSection} exact />
            <PrivateRoute path="/tutorial/section/show/:id" component={ShowSection} exact />
            <PrivateRoute path="/tutorial/section/edit/:id" component={EditSection} exact />
            <PrivateRoute path="/tutorial/user/section" component={ListSection} exact />
            <PrivateRoute path="/tutorial/user/tutorial/:id" component={UserTutorial} exact />
            <PrivateRoute path="/history/new" component={NewHistory} exact />
            <PrivateRoute path="/history/edit/:id" component={EditHistory} exact />
            <PrivateRoute path="/history/historytabs/:id" component={HistoryTabs} exact />
            <PrivateRoute path="/checkout/:id" component={Checkout} exact />
            <PrivateRoute path="/checkout/payment/success/:id" component={SuccessPayment} exact />
            <PrivateRoute path="/checkout/payment/success/:course/:user/:payment" component={SuccessPayment} exact />
            <PrivateRoute path="/payment" component={PaymentList} exact />
            <PrivateRoute path="/payment/show/:id" component={ShowPayment} exact />
            <PrivateRoute path="/grantrequirement" component={GrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/custom/new" component={NewCustomGrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/custom/edit/:id" component={EditCustomGrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/custom/show/:id" component={ShowCustomGrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/default/new" component={NewDefaultGrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/default/edit/:id" component={EditDefaultGrantRequirement} exact />
            <PrivateRoute path="/grantrequirement/default/show/:id" component={ShowDefaultGrantRequirement} exact />
            <PrivateRoute path="/tracking" component={TrackingList} exact />
            <PrivateRoute path="/tracking/show/:id" component={ShowTracking} exact />
            <PrivateRoute path="/recognition/list" component={RecognitionList} exact />
            <PrivateRoute path="/welcomepackage" component={WelcomePackage} exact />
            <PrivateRoute path="/welcomepackage/wellness" component={WellnessWelcomePackage} exact />
            <PrivateRoute path="/progresscontrol/matching/:id" component={ProgressControlMatching} exact />
            <PrivateRoute path="/progresscontrol" component={ProgressControlCourses} exact />
            <Redirect from="/" to="/login" />
          </Switch>
        </Suspense>
      </Router>,
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);


import { EDIT_TRANSLATION } from "constants/actionTypes";
import { RESET_SHOW_TRANSLATION } from "constants/actionTypes";
import { GENERAL_TRANSLATION_LIST } from "constants/actionTypes";
import { SHOW_NEXT_PENDING } from "constants/actionTypes";
import { SHOW_TRANSLATION } from "constants/actionTypes";
import { TRANSLATION_LANGUAGE_LIST } from "constants/actionTypes";
import { NEW_TRANSLATION } from "constants/actionTypes";

const initialState = {
  translation_language_list:[],
  general_translation_list:[],
  translation:{
    state:"ALL",
    language:"ALL"
  },
  new_translation:{
    language: "en",
  },
  show_translation:{
    language: "en",
    label:"",
    description:"",
    state:"",
  },
  show_next_pending:[],
  edit_translation:"",
  loading:true
}

export const translationReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSLATION_LANGUAGE_LIST:
          return Object.assign({}, state, {
            translation_language_list: action.payload,
            loading: false
          });
        case NEW_TRANSLATION:
          return Object.assign({}, state, {
            new_translation_success: action.payload,
          });
        case EDIT_TRANSLATION:
          return Object.assign({}, state, {
            edit_translation: action.payload,
          });  
        case SHOW_TRANSLATION:
          return Object.assign({}, state, {
            show_translation: action.payload,
          });
        case SHOW_NEXT_PENDING:
          return Object.assign({}, state, {
            show_next_pending: action.payload,
          });

        case GENERAL_TRANSLATION_LIST:
          return Object.assign({}, state, {
            general_translation_list: action.payload,
          });


        case RESET_SHOW_TRANSLATION:
          return Object.assign({}, state, {
            show_translation:{
              language: "en",
              label:"",
              description:"",
              state:"",
            },
            show_next_pending:[],
        });
      }
     return state;
}
  

import { GET_REPORTS } from "constants/actionTypes";
import { LAST_YEAR_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_MONTH_GRADUATES_REPORT } from "constants/actionTypes";
import { DATE_RANGE_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_SIX_MONTHS_GRADUATES_REPORT } from "constants/actionTypes";
import { GET_MBS_GRADUATES_BY_LANGUAGE } from "constants/actionTypes";
import { GET_REPORT_MODALITY_MAP } from "constants/actionTypes";
import { LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT } from "constants/actionTypes";
import { DATE_RANGE_WELLNESS_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from "constants/actionTypes";
import { DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_MONTH_WELLNESS_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_YEAR_WELLNESS_GRADUATES_REPORT } from "constants/actionTypes";
import { CHANGE_ACTIVE_TAB_REPORTS } from "constants/actionTypes";
import { LAST_YEAR_SA_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_MONTH_SA_GRADUATES_REPORT } from "constants/actionTypes";
import { DATE_RANGE_SA_GRADUATES_REPORT } from "constants/actionTypes";
import { LAST_SIX_MONTHS_SA_GRADUATES_REPORT } from "constants/actionTypes";
import { AMBASSADOR_STATISTICS, AMBASSADOR_GROUP_REPORTS } from "constants/actionTypes";
import { GET_REPORT_COUNTRY, GET_AMBASSADOR_COUNTRY, GET_REPORT_AMBASSADOR, GET_REPORT_GLOBAL_MAP } from "constants/actionTypes";
import { GET_GLOBAL_NUMBERS, STATISTICS_GLOBAL_AMBASSADOR } from "constants/actionTypes";

const initialState = {
  report_list: {
    studentsMbs: [],
    topNumbers: [],
    topNumbers2: [],
    statistics: [],
    evaluations: [],
    vectorMap: {
      BO: ""
    }
  },
  report_country: {
    MBS: [],
    JR: []
  },
  ambassador_country: [],
  ambassador_statistics: [],
  report_group_list: [],
  report_ambassador: {
    MBS: [],
    JR: []
  },
  global_numbers: {
    global_groups: 0
  },
  global_numbers_ambassador: {
    global_groups: 0
  },
  report_global_map: {
    topNumbers: [],
    vectorMap: []
  },
  report_modality_map: {
    modalityCountry: [],
    modalityLanguage: {
      en_total_graduates_online: '',
      en_total_graduates_face_to_face: '',
      es_total_graduates_online: '',
      es_total_graduates_face_to_face: '',
      fr_total_graduates_online: '',
      fr_total_graduates_face_to_face: '',
      pt_total_graduates_online: '',
      pt_total_graduates_face_to_face: '',
      zh_total_graduates_online: '',
      zh_total_graduates_face_to_face: '',
    }
  },
  last_year_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_month_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_six_months_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  date_range_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_year_sa_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_month_sa_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_six_months_sa_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  date_range_sa_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_year_wellness_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_month_wellness_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_six_months_wellness_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  date_range_wellness_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_year_wellness_participant_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_month_wellness_participant_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  last_six_months_wellness_participant_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  date_range_wellness_participant_graduates: {
    graduates: 0,
    high: 80,
    graduates_high: 800,
    graduates_report: [],
    graduates_list: [],
    start_date: new Date(),
    final_date: new Date(),
    graduates_total: 0,
  },
  initial_report: {
    start_date: new Date(),
    final_date: new Date(),
    country: "ALL",
    language: "ALL",
    graduates_total: 0
  },
  get_mbs_graduates_by_language: {
  },
  q10_data: {
    percentage_option1:	0,
    percentage_option2:	0,
    percentage_option3:	0,
    percentage_option4:	0,
    percentage_option5:	0,
    percentage_option6:	0,
    percentage_option7:	0,
    option1:	0,
    option2:	0,
    option3:	0,
    option4:	0,
    option5:	0,
    option6:	0,
    option7:	0
  },
  active_tab_reports: 0,
  loading: true,
}
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return Object.assign({}, state, {
        report_list: action.payload,
        loading: false
      });
    case GET_REPORT_COUNTRY:
      return Object.assign({}, state, {
        report_country: action.payload.REPORTS,
      });
    case GET_AMBASSADOR_COUNTRY:
      return Object.assign({}, state, {
        ambassador_country: action.payload,
      });
    case GET_REPORT_AMBASSADOR:
      return Object.assign({}, state, {
        report_ambassador: action.payload,
      });
    case GET_REPORT_GLOBAL_MAP:
      return Object.assign({}, state, {
        report_global_map: action.payload,
      });
    case GET_GLOBAL_NUMBERS:
      return Object.assign({}, state, {
        global_numbers: action.payload,
      });

    case AMBASSADOR_STATISTICS:
      return Object.assign({}, state, {
        ambassador_statistics: action.payload,
        loading: false
      });
    case AMBASSADOR_GROUP_REPORTS:
      return Object.assign({}, state, {
        report_group_list: action.payload,
        loading: false
      });
    case STATISTICS_GLOBAL_AMBASSADOR:
      return Object.assign({}, state, {
        global_numbers_ambassador: action.payload,
        loading: false
      });
    case LAST_YEAR_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_year_graduates: action.payload,
        loading: false
      });
    case LAST_SIX_MONTHS_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_six_months_graduates: action.payload,
        loading: false
      });
    case LAST_MONTH_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_month_graduates: action.payload,
        loading: false
      });
    case DATE_RANGE_GRADUATES_REPORT:
      return Object.assign({}, state, {
        date_range_graduates: action.payload,
        loading: false
      });
    case LAST_YEAR_SA_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_year_sa_graduates: action.payload,
        loading: false
      });
    case LAST_SIX_MONTHS_SA_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_six_months_sa_graduates: action.payload,
        loading: false
      });
    case LAST_MONTH_SA_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_month_sa_graduates: action.payload,
        loading: false
      });
    case DATE_RANGE_SA_GRADUATES_REPORT:
      return Object.assign({}, state, {
        date_range_sa_graduates: action.payload,
        loading: false
      });
    case GET_MBS_GRADUATES_BY_LANGUAGE:
      return Object.assign({}, state, {
        get_mbs_graduates_by_language: action.payload,
        loading: false
      });
    case CHANGE_ACTIVE_TAB_REPORTS:
      return Object.assign({}, state, {
        active_tab_reports: action.payload,
      });
    case GET_REPORT_MODALITY_MAP:
      return Object.assign({}, state, {
        report_modality_map: action.payload,
      });
    case LAST_YEAR_WELLNESS_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_year_wellness_graduates: action.payload,
        loading: false
      });
    case LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_six_months_wellness_graduates: action.payload,
        loading: false
      });
    case LAST_MONTH_WELLNESS_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_month_wellness_graduates: action.payload,
        loading: false
      });
    case DATE_RANGE_WELLNESS_GRADUATES_REPORT:
      return Object.assign({}, state, {
        date_range_wellness_graduates: action.payload,
        loading: false
      });
    case LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_year_wellness_participant_graduates: action.payload,
        loading: false
      });
    case LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_six_months_wellness_participant_graduates: action.payload,
        loading: false
      });
    case LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT:
      return Object.assign({}, state, {
        last_month_wellness_participant_graduates: action.payload,
        loading: false
      });
    case DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT:
      return Object.assign({}, state, {
        date_range_wellness_participant_graduates: action.payload,
        loading: false
      });
  }
  return state;
}
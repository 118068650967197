import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { translate } from 'react-switch-lang';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// react component for creating dynamic tables
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// @material-ui/icons
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import { IconButton } from "@material-ui/core";

// core components
import CustomDropdown from "components/CustomDropdown/CustomCourseDropDown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import { getActiveUser, logoutUser } from "actions/loginActions.jsx";

import en from 'assets/translate/en.json';
import fr from 'assets/translate/fr.json';
import es from 'assets/translate/es.json';
import pt from 'assets/translate/pt.json';
import hi from 'assets/translate/hi.json';
import zh from 'assets/translate/zh.json';
import { setTranslations } from 'react-switch-lang';


import { showPrinciple, nextPrinciple } from "actions/courseActions";
import { isUndefined } from "lodash";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { updateWizardActiveTab } from "actions/generalActions";
import { waitingPrincipleLoad } from "actions/courseActions";

setTranslations({ en, fr, es, pt, hi, zh });


class CourseHeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.logoutUser = this.logoutUser.bind(this);
    this.redirectClick = this.redirectClick.bind(this);
    this.handleRequirementClick = this.handleRequirementClick.bind(this)
    this.navigationClick = this.navigationClick.bind(this);
  }

  componentWillMount() {
    this.props.dispatchGetActiveUser(this.props.history);
  }

  logoutUser() {
    this.props.dispatchLogoutUser(this.props.history);
  }
  redirectClick(key) {
    this.props.dispatchWaitingPrincipleLoad();
    this.props.dispatchShowPrinciple(key);
  }
  navigationClick(key) {
    this.props.dispatchWaitingPrincipleLoad();
    this.props.dispatchShowPrinciple(key);
    if (this.props.course_view === "editor") {
      this.props.history.push('/principle/show/' + key);
    } else {
      this.props.history.push('/principle/content/' + key);
    }
  }
  handleRequirementClick(key) {
    this.props.dispatchUpdateWizardActiveTab(key);
  }

  activeStopStateList = () => {
    const { course_student_stop_list } = this.props;
    const principlesId = []
    course_student_stop_list.map(prop => {
      if (prop.state === "state.active") principlesId.push(prop.principle.id);
    })
    return principlesId;
  }

  principlesArrayList = () => {
    const { unit_student_list } = this.props;
    var progress_list = [], allPrinciples_list = [], notAllowed_list = [];
    let stopState = false;
    if (!isUndefined(unit_student_list.units)) {
      unit_student_list.units.map((unit) => {
        unit.principles.map((principle) => {
          if (principle.state === "state.published") {
            allPrinciples_list.push(principle.id);
          }
          if (principle.state === "state.published" && principle.description !== "undefined" && principle.description !== undefined) {
            if (!stopState) progress_list.push(principle.id);
          }
          if (principle.state === "state.published" && principle.description === "undefined" || principle.description === undefined) {
            notAllowed_list.push(principle.id);
          }
        })
      })
    }
    return { "allPrinciples": allPrinciples_list, "progress": progress_list, "denied": notAllowed_list };
  }

  render() {
    const { classes, t, active_user, unit_list, show_principle, show_course, course_view, response_obtained, show_stop } = this.props;
    let previous, next;
    let unit_dropdawn_list = [];
    let principle_index_list = [];
    let next_principle_index_list = [];
    const actual = parseInt(this.props.match.params.id);
    let roles = active_user.roles === undefined ? [] : active_user.roles;
    const principlesArrayList = this.principlesArrayList();
    const activeStopStateList = this.activeStopStateList();
    const progress = roles.includes("ROLE_ADMIN") || roles.includes("ROLE_LANGUAGE_ADMIN") ? "" : (((principlesArrayList["progress"].length / principlesArrayList["allPrinciples"].length) * 100).toFixed(0) + "%")
    unit_list.map((unit) => {
      if (course_view === "student") {
        unit_dropdawn_list.push(
          { divider: true },
          <center><b>{unit.name}</b></center>,
          { divider: true },
        )
        unit.principles.map((principle) => {
          if (principle.state === "state.published") {
            if (principlesArrayList["progress"].includes(principle.id) || (principlesArrayList["denied"][0] === principle.id && principlesArrayList["progress"].length === 0) || (principlesArrayList["denied"][0] === principle.id && principlesArrayList["progress"].length !== 0 && activeStopStateList.length === 0) && (show_stop.state !== "state.active" && principlesArrayList["denied"][0]===principle.id)) {
              principle_index_list.push(principle.id)
              unit_dropdawn_list.push(
                <Link to={'/principle/content/' + principle.id} onClick={() => this.redirectClick(principle.id)} className={classes.dropdownLink}>
                  {principlesArrayList["progress"].includes(principle.id) ? <a style={{ color: "green" }}>✔   </a> : ""}


                  {principle.name}
                </Link>
              );
            } else {
              unit_dropdawn_list.push(
                <a className={classes.dropdownLink} style={{ color: "gray" }}>{principle.name}</a>
              )
            }
          }
        })
      }
      else if (course_view === "editor") {
        unit_dropdawn_list.push(
          { divider: true },
          <center><b>{unit.name}</b></center>,
          { divider: true },
        )
        unit.principles.map((principle) => {
          principle_index_list.push(principle.id)
          unit_dropdawn_list.push(
            <Link to={'/principle/show/' + principle.id} onClick={() => this.redirectClick(principle.id)} className={classes.dropdownLink}>
              {principle.name}
            </Link>
          );

        })

      }

      unit.principles.map((principle) => {
        next_principle_index_list.push(principle.id);
      })
    })


    next_principle_index_list.map((i, key) => {
      if (actual === i) {
        let button_next = next_principle_index_list[(key + 1)]
        this.props.dispatchNextPrinciple(button_next);
      }
    })

    principle_index_list.map((i, key) => {
      if (actual === i) {
        previous = principle_index_list[(key - 1)]
        next = principle_index_list[(key + 1)]
      }
    })

    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem} style={{ borderRight: "1px solid #0000001F" }}>
          {
            <Link to={course_view === "editor" ? '/course/show/' + show_course.id : '/course/content/' + show_course.id} className={classes.navLink}>
              <ArrowBack className={classes.icons} />
              {t("button_return_course_content")}
            </Link>
          }
        </ListItem>

        <ListItem className={classes.listItem} style={{ borderRight: "1px solid #0000001F" }}>
          {
            <Link to={course_view === "editor" ? '/course/show/' + show_course.id : '/course/content/' + show_course.id} className={classes.navLink} onClick={() => this.handleRequirementClick(1)}>
              <ChromeReaderMode className={classes.icons} />
              {t("link_requirements")}
            </Link>
          }
        </ListItem>
        {
          isUndefined(previous) ? ""
            :
            <ListItem className={classes.listItem}>
              <IconButton>
                <ArrowBackIos color="danger" className={classes.icons} onClick={response_obtained ? () => this.navigationClick(previous) : ""} />
              </IconButton>
            </ListItem>

        }


        <ListItem className={classes.listItem}>
          <CustomDropdown
            hoverColor="info"
            buttonText={
              show_principle.name.length > 25 ?
                show_principle.name.substring(0, 25) + "...  " + progress
                :
                show_principle.name + "  " + progress
            }
            buttonProps={{
              round: true,
              fullWidth: true,
              style: { marginBottom: "0" },
              color: "transparent",
            }}
            dropdownList={unit_dropdawn_list}
          />
        </ListItem>
        {
          isUndefined(next) || show_stop.state === "state.active" ? ""
            :
            <ListItem className={classes.listItem}>
              <IconButton>
                <ArrowForwardIos color="danger" className={classes.icons} onClick={response_obtained ? () => this.navigationClick(next) : ""} />
              </IconButton>
            </ListItem>

        }
      </List>
    );
  }
}

const mapStateToProps = state => ({
  active_user: state.loginReducer.active_user,
  unit_list: state.courseReducer.unit_list,
  show_principle: state.courseReducer.show_principle,
  show_course: state.courseReducer.show_course,
  course_view: state.courseReducer.course_view,
  response_obtained: state.generalReducer.response_obtained,
  unit_student_list: state.studentReducer.unit_student_list,
  show_stop: state.studentReducer.show_stop,
  course_student_stop_list: state.studentReducer.course_student_stop_list,
});

const mapDispatchToPropsActions = dispatch => ({
  dispatchGetActiveUser: redirect => dispatch(getActiveUser(redirect)),
  dispatchLogoutUser: redirect => dispatch(logoutUser(redirect)),
  dispatchShowPrinciple: key => dispatch(showPrinciple(key)),
  dispatchNextPrinciple: key => dispatch(nextPrinciple(key)),
  dispatchUpdateWizardActiveTab: key => dispatch(updateWizardActiveTab(key)),
  dispatchWaitingPrincipleLoad: () => dispatch(waitingPrincipleLoad())

});

const LoginFormComponent = translate(withStyles(headerLinksStyle)(CourseHeaderLinks));
export default withRouter(connect(mapStateToProps, mapDispatchToPropsActions)(LoginFormComponent));


import history from "../history";
import { LIST_COURSE } from "constants/actionTypes";
import { LOAD_FORM_COURSE } from "constants/actionTypes";
import { SUCCESSFULL_NEW } from "constants/actionTypes";
import { jsonToArray } from "assets/functions/general.jsx";
import { SHOW_COURSE } from "constants/actionTypes";
import { SHOW_UNIT } from "constants/actionTypes";
import { SHOW_PRINCIPLE } from "constants/actionTypes";
import { SUCCESSFULL_REDIRECT } from "constants/actionTypes";
import { DELETE_COURSE } from "constants/actionTypes";
import { DELETE_PRINCIPLE } from "constants/actionTypes";
import { DELETE_UNIT } from "constants/actionTypes";
import { SUCCESSFULL_EDIT } from "constants/actionTypes";
import { EDIT_UNIT } from "constants/actionTypes";
import { EDIT_PRINCIPLE } from "constants/actionTypes";
import { LOAD_FORM_PRINCIPLE } from "constants/actionTypes";
import { LOAD_FORM_UNIT } from "constants/actionTypes";
import { DELETE_SUCCESSFUL } from "constants/actionTypes";
import { UNIT_LIST } from "constants/actionTypes";
import { UPDATE_UNIT_TREE } from "constants/actionTypes";
import { UPDATE_UNITLIST_SELECT } from "constants/actionTypes";
import { CONTENT_LIST } from "constants/actionTypes";
import { UPDATE_PRINCIPLE_ID } from "constants/actionTypes";
import { CHANGE_NEXT_PRINCIPLE } from "constants/actionTypes";
import { UPDATE_UNIT_ID } from "constants/actionTypes";
import { COURSE_VIEW_STUDENT } from "constants/actionTypes";
import { COURSE_VIEW_EDITOR } from "constants/actionTypes";
import { RESPONSE_OBTAINED } from "constants/actionTypes";
import { CHANGE_SUCCESSFUL } from "constants/actionTypes";
import { CHANGE_PRINCIPLE_NAME_WARNING } from "constants/actionTypes";
import { CHANGE_UNIT_NAME_WARNING } from "constants/actionTypes";
import { UNIT_LIST_PUBLISHED } from "constants/actionTypes";
import { ANSWER_LIST } from "constants/actionTypes";
import { SHOW_ANSWER } from "constants/actionTypes";
import { STOP_STATE_LIST } from "constants/actionTypes";
import { SUGGESTION_WARNING } from "constants/actionTypes";
import { PROGRESS_STATE_LIST } from "constants/actionTypes";
import { PROGRESS_SHOW_NEXT_PENDING } from "constants/actionTypes";
import { SHOW_PROGRESS_PENDING_CORRECTION } from "constants/actionTypes";
import { WAITING_PRINCIPLE_LOAD } from "constants/actionTypes";
import { RESET_WAITING_PRINCIPLE_LOAD } from "constants/actionTypes";
import { SESSION_EXPIRED } from "constants/actionTypes";
import { COURSE_PAYMENT } from "constants/actionTypes";
import { LIST_PAYMENT } from "constants/actionTypes";
import { SHOW_PAYMENT } from "constants/actionTypes";
import { WAITING_STUDENTS_TRANSFER } from "constants/actionTypes";
import { SUCCESSFUL_TRANSFER_STUDENTS } from "constants/actionTypes";
import { COURSE_PUBLISHED } from "constants/actionTypes";
import { THEREIS_COURSE_PUBLISHED } from "constants/actionTypes";
import { ANSWER_DATE_LIST } from "constants/actionTypes";

export const listCourse = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/course", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LIST_COURSE, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const newCourse = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("user_id", reduxState.loginReducer.active_user.id);
        urlencoded.append("name", reduxState.form.courseNewform.values.name);
        urlencoded.append("language", reduxState.form.courseNewform.values.language);
        urlencoded.append("state", reduxState.form.courseNewform.values.state);
        urlencoded.append("description", reduxState.form.courseNewform.values.description);
        urlencoded.append("roles", jsonToArray(reduxState.form.courseNewform.values.roles));
        urlencoded.append("picture", reduxState.form.courseNewform.values.picture);
        urlencoded.append("code", reduxState.form.courseNewform.values.code);
        urlencoded.append("requirements", reduxState.form.courseNewform.values.requirements);
        urlencoded.append("price", reduxState.form.courseNewform.values.price);
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_COURSE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newUnit = (course_id) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", course_id);
        urlencoded.append("name", reduxState.form.unitNewform.values.name);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_UNIT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newPrinciple = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.principleNewform.values.name);
        urlencoded.append("state", reduxState.form.principleNewform.values.state);
        urlencoded.append("unit_id", reduxState.form.principleNewform.values.unit);
        urlencoded.append("description", reduxState.form.principleNewform.values.description);
        urlencoded.append("content", reduxState.form.principleNewform.values.content);
        urlencoded.append("finalAnswerType", reduxState.form.principleNewform.values.answerType);
        urlencoded.append("finalAnswer", reduxState.form.principleNewform.values.answerType === "option.checkbox" ? "" : reduxState.form.principleNewform.values.finalAnswer);
        urlencoded.append("stopState", "state.inactive");
        urlencoded.append("position", 0);
        urlencoded.append("type", 0);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PRINCIPLE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showCourse = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/course/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    // history.push("/login");
                } else {
                    dispatch({ type: SHOW_COURSE, payload: json.data });
                    dispatch({ type: LOAD_FORM_COURSE, data: json.data });
                }
            })
            .catch(() => {
                //history.push("/login");
            });
    }
};

export const showCourseEdit = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/course/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_COURSE, payload: json.data });
                    dispatch({ type: LOAD_FORM_COURSE, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const loadFormCourse = data => ({ type: LOAD_FORM_COURSE, data });

export const showUnit = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_UNIT, payload: json.data });
                    dispatch({ type: LOAD_FORM_UNIT, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showUnitList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_course.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/list/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UNIT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showUnitList2 = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/list/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    // history.push("/login");
                } else {
                    dispatch({ type: UNIT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                // history.push("/login");
            })
    }
};

export const showUnitListPublished = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/list/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UNIT_LIST_PUBLISHED, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showContentList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/list/" + reduxState.courseReducer.show_course.id, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: CONTENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showContentList2 = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/list/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: CONTENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showPrinciple = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        dispatch({ type: DELETE_SUCCESSFUL });
        return fetch(reduxState.loginReducer.base_url + "/principle/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PRINCIPLE, payload: json.data });
                    dispatch({ type: LOAD_FORM_PRINCIPLE, data: json.data });
                    dispatch({ type: RESPONSE_OBTAINED });
                    dispatch({ type: RESET_WAITING_PRINCIPLE_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editCourse = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.courseEditform.values.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.courseEditform.values.name);
        urlencoded.append("language", reduxState.form.courseEditform.values.language);
        urlencoded.append("state", reduxState.form.courseEditform.values.state);
        urlencoded.append("description", reduxState.form.courseEditform.values.description);
        urlencoded.append("roles", jsonToArray(reduxState.form.courseEditform.values.roles));
        urlencoded.append("picture", reduxState.form.courseEditform.values.picture);
        urlencoded.append("code", reduxState.form.courseEditform.values.code);
        urlencoded.append("price", reduxState.form.courseEditform.values.price);
        urlencoded.append("requirements", reduxState.form.courseEditform.values.requirements);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editUnit = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.unitEditform.values.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.unitEditform.values.name);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_UNIT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editPrinciple = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.principleEditform.values.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.principleEditform.values.name);
        urlencoded.append("state", reduxState.form.principleEditform.values.state);
        urlencoded.append("description", reduxState.form.principleEditform.values.description);
        urlencoded.append("content", reduxState.form.principleEditform.values.content);
        urlencoded.append("type", reduxState.courseReducer.show_principle.type);
        urlencoded.append("position", reduxState.courseReducer.show_principle.position);
        urlencoded.append("finalAnswerType", reduxState.form.principleEditform.values.final_answertype);
        urlencoded.append("finalAnswer", reduxState.form.principleEditform.values.final_answer === "option.checkbox" ? "" : reduxState.form.principleEditform.values.final_answer);
        urlencoded.append("stopState", reduxState.form.principleEditform.values.stop_state);
        urlencoded.append("stopContent", reduxState.form.principleEditform.values.stop_content !== undefined ? reduxState.form.principleEditform.values.stop_content : "");
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_PRINCIPLE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const orderUpdate = (children_array) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("principle_array", children_array);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/orderupdate", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const orderUnitUpdate = (children_array) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("principle_array", children_array);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/orderunitupdate", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch(showUnitList());
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const deleteCourse = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/course/delete/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_COURSE, payload: json.data });
                    dispatch({ type: DELETE_SUCCESSFUL });
                    history.push('/course');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const deleteUnit = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/delete/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_UNIT, payload: json.data });
                    dispatch({ type: DELETE_SUCCESSFUL });
                    dispatch(showUnitList());
                    history.push('/course/show/' + reduxState.courseReducer.show_course.id);
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const deletePrinciple = (key) => {
    return (dispatch, getState) => {
        var myHeaders = new Headers();
        const reduxState = getState();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/delete/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_PRINCIPLE, payload: json.data });
                    dispatch({ type: DELETE_SUCCESSFUL });
                    dispatch(showUnitList());
                    history.push('/course/show/' + reduxState.courseReducer.show_course.id);
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const cloneCourse = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("user_id", reduxState.loginReducer.active_user.id);
        urlencoded.append("name", reduxState.form.courseCloneform.values.name);
        urlencoded.append("language", reduxState.form.courseCloneform.values.language);
        urlencoded.append("state", reduxState.form.courseCloneform.values.state);
        urlencoded.append("description", reduxState.form.courseCloneform.values.description);
        urlencoded.append("roles", jsonToArray(reduxState.form.courseCloneform.values.roles));
        urlencoded.append("picture", reduxState.form.courseCloneform.values.picture);
        urlencoded.append("code", reduxState.form.courseCloneform.values.code);
        urlencoded.append("requirements", reduxState.form.courseCloneform.values.requirements);
        urlencoded.append("price", reduxState.form.courseCloneform.values.price);
        urlencoded.append("clone_id", reduxState.courseReducer.show_course.id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/clone", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_COURSE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const cloneUnit = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", reduxState.courseReducer.show_unit.course.id);
        urlencoded.append("name", reduxState.form.unitCloneform.values.name);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_UNIT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const clonePrinciple = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", reduxState.form.principleCloneform.values.name);
        urlencoded.append("state", reduxState.form.principleCloneform.values.state);
        urlencoded.append("unit_id", reduxState.form.principleCloneform.values.unit);
        urlencoded.append("description", reduxState.form.principleCloneform.values.description);
        urlencoded.append("content", reduxState.form.principleCloneform.values.content);
        urlencoded.append("finalAnswerType", reduxState.form.principleCloneform.values.final_answertype);
        urlencoded.append("finalAnswer", reduxState.form.principleCloneform.values.final_answer === "option.checkbox" ? "" : reduxState.form.principleCloneform.values.final_answer);
        urlencoded.append("position", 0);
        urlencoded.append("type", 0);
        urlencoded.append("stopState", reduxState.courseReducer.show_principle.stop_state);
        urlencoded.append("stopContent", reduxState.courseReducer.show_principle.stop_content !== undefined ? reduxState.courseReducer.show_principle.stop_content : "");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PRINCIPLE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const clearSuccessfulClone = () => ({ type: DELETE_SUCCESSFUL })

export const showCourseClone = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    json.data.name += " [copy]";
                    dispatch({ type: SHOW_COURSE, payload: json.data });
                    dispatch({ type: LOAD_FORM_COURSE, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showUnitClone = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    json.data.name += " [copy]";
                    dispatch({ type: SHOW_UNIT, payload: json.data });
                    dispatch({ type: LOAD_FORM_UNIT, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showPrincipleClone = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    json.data.name += " [copy]"
                    dispatch({ type: SHOW_PRINCIPLE, payload: json.data });
                    dispatch({ type: LOAD_FORM_PRINCIPLE, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showCourseRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_course.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push('/course/show/' + key);
    }
}

export const showUnitRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_course.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push('/course/show/' + key);
    }
}

export const showPrincipleRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_principle.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push('/principle/show/' + key);
    }
}

export const updateUnitSelect = params => {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_UNITLIST_SELECT, payload: params });
    }
}
export const hideAlert = () => ({ type: DELETE_SUCCESSFUL })
export const updateUnitTree = (data) => ({ type: UPDATE_UNIT_TREE, payload: data })

export const changePrincipleNameWarning = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        dispatch({ type: DELETE_SUCCESSFUL });
        return fetch(reduxState.loginReducer.base_url + "/principle/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PRINCIPLE, payload: json.data });
                    dispatch({ type: CHANGE_PRINCIPLE_NAME_WARNING })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const changePrincipleName = (e) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_principle.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", e);
        urlencoded.append("state", reduxState.courseReducer.show_principle.state);
        urlencoded.append("description", reduxState.courseReducer.show_principle.description);
        urlencoded.append("content", reduxState.courseReducer.show_principle.content);
        urlencoded.append("type", reduxState.courseReducer.show_principle.type);
        urlencoded.append("position", reduxState.courseReducer.show_principle.position);
        urlencoded.append("finalAnswerType", reduxState.courseReducer.show_principle.final_answertype);
        urlencoded.append("finalAnswer", reduxState.courseReducer.show_principle.final_answer);
        urlencoded.append("stopState", reduxState.courseReducer.show_principle.stop_state);
        urlencoded.append("stopContent", reduxState.courseReducer.show_principle.stop_content !== undefined ? reduxState.courseReducer.show_principle.stop_content : "");
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_PRINCIPLE, payload: json.data });
                    dispatch({ type: CHANGE_SUCCESSFUL });
                    dispatch(showUnitList());
                    history.push('/course/show/' + reduxState.courseReducer.show_course.id);
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const changeUnitNameWarning = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        dispatch({ type: DELETE_SUCCESSFUL });
        return fetch(reduxState.loginReducer.base_url + "/unit/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_UNIT, payload: json.data });
                    dispatch({ type: CHANGE_UNIT_NAME_WARNING })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const changeUnitName = (e) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.courseReducer.show_unit.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", e);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/unit/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_UNIT, payload: json.data });
                    dispatch({ type: CHANGE_SUCCESSFUL });
                    dispatch(showUnitList());
                    history.push('/course/show/' + reduxState.courseReducer.show_course.id);
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const changePrincipleState = (data) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("state", data.state === "state.published" ? "state.draft" : "state.published");
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/principle/editstate/" + data.id, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch(showUnitList());
                    history.push('/course/show/' + reduxState.courseReducer.show_course.id);
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const listAnswer = (course, student) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/answer/list/" + course + "/" + student, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: ANSWER_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const listDateAnswer = (course, student) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/answer/date/list/" + course + "/" + student, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: ANSWER_DATE_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showAnswer = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/answer/" + key + "/" + reduxState.loginReducer.active_user.id, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_ANSWER, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showProgress = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_ANSWER, payload: json.data });
                    dispatch({ type: SUGGESTION_WARNING })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const updatePrincipleId = data => ({ type: UPDATE_PRINCIPLE_ID, payload: data });
export const updateUnitId = data => ({ type: UPDATE_UNIT_ID, payload: data });
export const nextPrinciple = data => ({ type: CHANGE_NEXT_PRINCIPLE, payload: data });
export const courseViewStudent = () => ({ type: COURSE_VIEW_STUDENT });
export const courseViewEditor = () => ({ type: COURSE_VIEW_EDITOR });
export const changeSuccessful = () => ({ type: CHANGE_SUCCESSFUL });

export const listStopState = (state) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("state", state);
        urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);
        urlencoded.append("language", reduxState.loginReducer.active_user.language_grader);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/stop/show/state/all", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: STOP_STATE_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const resetStudentStop = (student, course) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/stop/reset/" + student + "/" + course, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch(listStopState("state.active"))
                    dispatch({ type: DELETE_SUCCESSFUL });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const editComment = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("comment", reduxState.form.suggestionform.values.comment);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/edit/comment/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};


export const listProgressState = (course, student, state) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", course);
        urlencoded.append("student_id", student);
        urlencoded.append("state", state);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/list/state", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PROGRESS_STATE_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showProgressNextPending = (course, student) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", course);
        urlencoded.append("student_id", student);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/nextpending", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    if (json.code !== 500) {
                        dispatch({ type: PROGRESS_SHOW_NEXT_PENDING, payload: json.data })
                    } else (
                        dispatch({ type: PROGRESS_SHOW_NEXT_PENDING, payload: [] })
                    )
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showProgressPendingCorrection = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("student_id", key);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/show/pendingcorrection", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PROGRESS_PENDING_CORRECTION, payload: json.data })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const transferStudents = (initial_course_id, final_course_id) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("initial_course_id", initial_course_id);
        urlencoded.append("final_course_id", final_course_id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        dispatch({ type: WAITING_STUDENTS_TRANSFER, payload: true })
        return fetch(reduxState.loginReducer.base_url + "/progress/transferstudents", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: WAITING_STUDENTS_TRANSFER, payload: false })
                    dispatch({ type: SUCCESSFUL_TRANSFER_STUDENTS, payload: true })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const hideSuccessTransferStudents = () => ({ type: SUCCESSFUL_TRANSFER_STUDENTS, payload: false })

export const getCoursePayment = course_id => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        let student_id = reduxState.loginReducer.active_user.id;
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/payment/student/" + student_id + "/" + course_id, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: COURSE_PAYMENT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showPayment = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/payment/show/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_PAYMENT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const listPayment = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/payment", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LIST_PAYMENT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const newPayment = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("price", reduxState.courseReducer.show_course.price);
        urlencoded.append("student_id", reduxState.loginReducer.active_user.id);
        urlencoded.append("course_id", reduxState.courseReducer.show_course.id);
        urlencoded.append("transaction_id", key);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/payment/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const checkCoursePublished = (language, requirements) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("language", language);
        urlencoded.append("requirements", requirements);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/published", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    if (json.data.length !== 0) {
                        dispatch({ type: COURSE_PUBLISHED, payload: json.data });
                        dispatch(setTherheIsCoursePublished(true));
                    } else {
                        dispatch(newCourse())
                    }
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const checkCoursePublishedEdit = (language, requirements, course) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("language", language);
        urlencoded.append("requirements", requirements);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/course/published", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    if (json.data.length !== 0 && json.data[0].id != course) {
                        dispatch({ type: COURSE_PUBLISHED, payload: json.data });
                        dispatch(setTherheIsCoursePublished(true));
                    } else {
                        dispatch(editCourse())
                    }
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};



export const continueCourseRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const unit_student_list = reduxState.studentReducer.unit_student_list;
        const course_student_stop_list = reduxState.studentReducer.course_student_stop_list;
        const activeStopStateList = course_student_stop_list
            .filter(prop => prop.state === "state.active")
            .map(prop => prop.principle.id);

        const allPrinciples_list = [];
        const progress_list = [];

        if (unit_student_list && unit_student_list.units) {
            unit_student_list.units.forEach(unit => {
                unit.principles.forEach(principle => {
                    if (principle.state === "state.published") {
                        allPrinciples_list.push(principle.id);

                        if (principle.description !== "undefined" && principle.description !== undefined) {
                            progress_list.push(principle.id);
                        }
                    }
                });
            });
        }
        let link;
        if (activeStopStateList.length === 0 && allPrinciples_list.length !== progress_list.length) {
            link = progress_list.length === 0
                ? allPrinciples_list[0]
                : allPrinciples_list[progress_list.length];
        } else {
            link = progress_list.length === 0
                ? allPrinciples_list[0]
                : progress_list[progress_list.length - 1];
        }
        history.push(`/principle/content/${link}`);
    }
}


export const setTherheIsCoursePublished = (bool) => ({ type: THEREIS_COURSE_PUBLISHED, payload: bool })

export const waitingPrincipleLoad = () => ({ type: WAITING_PRINCIPLE_LOAD })
export const resetWaitingPrincipleLoad = () => ({ type: RESET_WAITING_PRINCIPLE_LOAD })
import { SUCCESSFULL_EDIT, ERROR_REQUIRED_FIELDS, SUCCESS_REQUIRED_FIELDS, SUCCESSFUL_DELETE } from 'constants/actionTypes.jsx';
import { DISMATCH_PASSWORD, SUCCESSFULL_NEW, DELETE_SUCCESSFUL, UPDATE_FILE_NAME } from 'constants/actionTypes';
import { SUCCESSFULL_ACTIVE_USER } from 'constants/actionTypes';
import { CHANGE_ACTIVE_TAB } from 'constants/actionTypes';
import { IDLE_TIMER } from 'constants/actionTypes';
import { ERROR_SUBMIT } from 'constants/actionTypes';
import { SUCCESS_SUBMIT } from 'constants/actionTypes';
import { ERROR_EVALUATION } from 'constants/actionTypes';
import { SHOW_PROFILE_UPDATE } from 'constants/actionTypes';
import { CLEAR_PROFILE_UPDATE } from 'constants/actionTypes';
import { DELETE_WARNING } from 'constants/actionTypes';
import { GROUP_DELETE_WARNING, CHANGE_VERTICAL_ACTIVE_TAB } from 'constants/actionTypes';
import { SUCCESSFUL_LOAD } from 'constants/actionTypes';
import { ACTION_WARNING } from 'constants/actionTypes';
import { UPDATE_NAVPILLS_TAB } from 'constants/actionTypes';
import { UPDATE_WIZARD_TAB } from 'constants/actionTypes';
import { SAVED_LINK } from 'constants/actionTypes';
import { DELETE_SAVED_LINK } from 'constants/actionTypes';
import { getActiveUser } from './loginActions';
import { WAITING_RESPONSE } from 'constants/actionTypes';
import { SUGGESTION_WARNING } from 'constants/actionTypes';
import { LOADING_SEARCH } from 'constants/actionTypes';
import { CLEAN_LOADING_SEARCH } from 'constants/actionTypes';
import { RESET_WAITING_RESPONSE } from 'constants/actionTypes';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { CLEAN_SESSION_EXPIRED } from 'constants/actionTypes';

export const successfulEdit = () => ({ type: SUCCESSFULL_EDIT })
export const clearSuccessfulEdit = () => ({ type: DELETE_SUCCESSFUL })

export const successfulNew = (redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        dispatch({ type: SUCCESSFULL_NEW })
        if (reduxState.groupReducer.new_group.id !== undefined) {
            const key = reduxState.groupReducer.new_group.id
            redirect.push("/group/show/" + key);
        }
    }
}
export const deleteSuccessful = (redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        dispatch({ type: DELETE_SUCCESSFUL })
        if (reduxState.loginReducer.active_user.roles[0] == "ROLE_STUDENT") {
            redirect.push("/dashboard/student");
        }
    }
}

export const deleteSuccessfulRedirect = (redirect) => {
    return (dispatch) => {
        dispatch({ type: DELETE_SUCCESSFUL })
        redirect.push("/login");
    }
}

export const showProfileUpdate = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        if (!(reduxState.loginReducer.active_user.roles.includes("ROLE_LANGUAGE_ADMIN") || reduxState.loginReducer.active_user.roles.includes("ROLE_ADMIN")) && reduxState.loginReducer.active_user.areacode === "USA +1") {
            dispatch({ type: SHOW_PROFILE_UPDATE })
        }
    }
}

export const clearProfileUpdate = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_PROFILE_UPDATE })
        dispatch(getActiveUser())
    }
}

export const successfulLoad = () => ({ type: SUCCESSFUL_LOAD })
export const successfulDelete = () => ({ type: SUCCESSFUL_DELETE })
export const errorRequiredFields = () => ({ type: ERROR_REQUIRED_FIELDS })
export const successRequiredFields = () => ({ type: SUCCESS_REQUIRED_FIELDS })
export const dismatchPassword = () => ({ type: DISMATCH_PASSWORD })
export const idleTimerAlert = () => ({ type: IDLE_TIMER })
export const updateFileName = (key) => ({ type: UPDATE_FILE_NAME, payload: key })
export const changeActiveTab = (key) => ({ type: CHANGE_ACTIVE_TAB, payload: key })
export const updateNavpillsActiveTab = (key) => ({ type: UPDATE_NAVPILLS_TAB, payload: key })
export const updateWizardActiveTab = (key) => ({ type: UPDATE_WIZARD_TAB, payload: key })
export const changeVerticalActiveTab = (key) => ({ type: CHANGE_VERTICAL_ACTIVE_TAB, payload: key })
export const errorSubmit = () => ({ type: ERROR_SUBMIT })
export const successSubmit = () => ({ type: SUCCESS_SUBMIT })
export const errorEvaluation = () => ({ type: ERROR_EVALUATION })
export const deleteWarning = () => ({ type: DELETE_WARNING })
export const actionWarning = () => ({ type: ACTION_WARNING })
export const groupDeleteWarning = () => ({ type: GROUP_DELETE_WARNING })
export const hideAlert = () => ({ type: DELETE_SUCCESSFUL })
export const saveLink = (key) => ({ type: SAVED_LINK, payload: key })
export const DeleteSavedLink = () => ({ type: DELETE_SAVED_LINK })
export const waitingResponse = () => ({ type: WAITING_RESPONSE })
export const resetWaitingResponse = () => ({ type: RESET_WAITING_RESPONSE })
export const suggestionWarning = () => ({ type: SUGGESTION_WARNING })
export const loadingSearch = () => ({ type: LOADING_SEARCH })
export const cleanLoadingSearch = () => ({ type: CLEAN_LOADING_SEARCH })
export const sessionExpired = () => ({ type: SESSION_EXPIRED })
export const cleanSessionExpired = () => ({ type: CLEAN_SESSION_EXPIRED })





import { GRANT_LIST, MBS_IMAGE_ALERT, UPLOAD_IMAGE, DELETE_IMAGE_ALERT } from 'constants/actionTypes.jsx';
import { LOAD_FORM_GRANT, SHOW_GRANT, EDIT_GRANT, SUCCESSFULL_EDIT, NEW_GRANT, DELETE_GRANT, SUCCESSFULL_NEW, SHOW_GRANT_UPDATE } from 'constants/actionTypes';
import { SUCCESSFULL_REDIRECT } from 'constants/actionTypes';
import { NEW_GRANT_UPDATE } from 'constants/actionTypes';
import { GRANT_ACTIVE_LIST } from 'constants/actionTypes';
import { NEW_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { EDIT_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { LOAD_FORM_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SHOW_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SEND_REVISION_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SEND_CORRECTION_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SEND_REJECT_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SEND_APPROVED_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { GRANT_AMBASSADOR_LIST } from 'constants/actionTypes';
import { SUCCESSFUL_SEND } from 'constants/actionTypes';
import { SHOW_GRANT_DEADLINE } from 'constants/actionTypes';
import { GRANT_AMBASSADOR_APPLICATION } from 'constants/actionTypes';
import { NEW_GRANT_GROUP, SHOW_GRANT_USER } from 'constants/actionTypes';
import { SHOW_GRANT_GROUP_LIST, LOAD_FORM_GRANT_GROUP } from 'constants/actionTypes';
import { SHOW_GRANT_STATISTIC } from 'constants/actionTypes';
import { CLEAN_NEW_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SHOW_LIST_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { SHOW_GRANT_GRADUATES } from 'constants/actionTypes.jsx';

import { MENTORED_AMBASSADOR_LIST } from 'constants/actionTypes';
import { MENTORED_AMBASSADOR } from 'constants/actionTypes';
import { SHOW_REQUIREMENTS } from 'constants/actionTypes';
import { SHOW_APPROVED_APPLICATIONS } from 'constants/actionTypes';

import { convertDate } from 'assets/functions/general';
import { jsonToArray } from 'assets/functions/general.jsx';
import history from '../history';
import { SHOW_REJECTED_APPLICATIONS } from 'constants/actionTypes';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { GRANT_REQUIREMENT_LIST } from 'constants/actionTypes';
import { SHOW_GRANT_REQUIREMENT } from 'constants/actionTypes';
import { LOAD_FORM_GRANT_REQUIREMENT } from 'constants/actionTypes';
import { GRANTMEMBER_LIST } from 'constants/actionTypes';
import { CLEAN_GRANTMEMBER_LIST } from 'constants/actionTypes';
import { CLEAN_SHOW_GRANT_AMBASSADOR } from 'constants/actionTypes';
import { MEMBER_ALREADY_ADDED } from 'constants/actionTypes';
import { CLEAN_ADD_MEMBER_WARNING } from 'constants/actionTypes';
import { SHOW_AMBASSADOR_RECOGNITION } from 'constants/actionTypes';
import { AMBASSADOR_LEVEL } from 'constants/actionTypes';

export const showGrantGraduates = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/grant", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_GRADUATES, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getGrantList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const grantAmbassadorList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("state", "state.revision");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/ambassadorlist", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANT_AMBASSADOR_LIST, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const grantAmbassadorApplication = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/ambassadorapplication/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANT_AMBASSADOR_APPLICATION, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const approvedApplicationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/approved/list", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_APPROVED_APPLICATIONS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const rejectededApplicationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/rejected/list", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_REJECTED_APPLICATIONS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const getGrantActiveList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("language", reduxState.loginReducer.active_user.language);
        urlencoded.append("id_user", reduxState.loginReducer.active_user.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/active", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANT_ACTIVE_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showRequirements = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_grant", reduxState.grantReducer.show_grant.id);
        urlencoded.append("id_user", reduxState.loginReducer.active_user.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/requirement", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_REQUIREMENTS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const showGrant = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/show/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT, payload: json.data });
                    dispatch({ type: LOAD_FORM_GRANT, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });

    }
};

export const showGrantDeadline = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/deadline?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_DEADLINE, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showGrantAmbassador = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/showambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: NEW_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: LOAD_FORM_GRANT_AMBASSADOR, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const cleanShowGrantAmbassador = () => ({ type: CLEAN_SHOW_GRANT_AMBASSADOR });

export const showGrantGroup = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/showgroup/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_GROUP_LIST, data: json.data.groups });
                    dispatch({ type: LOAD_FORM_GRANT_GROUP, data: json.data.groups });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showGrantStatisticGroup = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grantstatistic/showgroup/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_GROUP_LIST, data: json.data.groups });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showGrantStatistic = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grantstatistic", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_STATISTIC, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};


export const loadFormGrantAmbassador = data => ({ type: LOAD_FORM_GRANT_AMBASSADOR, data });
export const loadFormGrantGroup = data => ({ type: LOAD_FORM_GRANT_GROUP, data });

export const showGrantUpdate = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/showupdate/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_UPDATE, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showGrantUser = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/showuser/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_USER, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showListGrantAmbassador = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/showlistambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_LIST_GRANT_AMBASSADOR, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const loadFormGrant = data => ({ type: LOAD_FORM_GRANT, data });
export const cleanNewGrantAmbassador = () => ({ type: CLEAN_NEW_GRANT_AMBASSADOR });

export const editGrant = () => {

    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.grantform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("title", reduxState.form.grantform.values.title);
        urlencoded.append("language", reduxState.form.grantform.values.language);
        urlencoded.append("description", reduxState.form.grantform.values.description);
        urlencoded.append("state", reduxState.form.grantform.values.state);
        urlencoded.append("type", reduxState.form.grantform.values.type);
        urlencoded.append("deadline_date", convertDate(reduxState.form.grantform.values.deadline_date));

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_GRANT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.grantAmbassadorform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("code", reduxState.form.grantAmbassadorform.values.code);
        urlencoded.append("number", reduxState.form.grantAmbassadorform.values.number);
        urlencoded.append("question1", reduxState.form.grantAmbassadorform.values.question1);
        urlencoded.append("question2", reduxState.form.grantAmbassadorform.values.question2);
        urlencoded.append("question3", reduxState.form.grantAmbassadorform.values.question3);
        urlencoded.append("question4", reduxState.form.grantAmbassadorform.values.question4);
        urlencoded.append("question5", reduxState.form.grantAmbassadorform.values.question5);
        urlencoded.append("question6", reduxState.form.grantAmbassadorform.values.question6);
        urlencoded.append("question7", reduxState.form.grantAmbassadorform.values.question7);
        urlencoded.append("question8", reduxState.form.grantAmbassadorform.values.question8);
        urlencoded.append("question9", reduxState.form.grantAmbassadorform.values.question9);
        urlencoded.append("question10", reduxState.form.grantAmbassadorform.values.question10);
        urlencoded.append("question11", reduxState.form.grantAmbassadorform.values.question11);
        urlencoded.append("question12", reduxState.form.grantAmbassadorform.values.question12);
        urlencoded.append("question13", reduxState.form.grantAmbassadorform.values.question13);
        urlencoded.append("question14", reduxState.form.grantAmbassadorform.values.question14);
        urlencoded.append("question15", reduxState.form.grantAmbassadorform.values.question15);
        urlencoded.append("file", reduxState.form.grantAmbassadorform.values.file);
        urlencoded.append("file2", reduxState.form.grantAmbassadorform.values.file2);
        urlencoded.append("file3", reduxState.form.grantAmbassadorform.values.file3);
        urlencoded.append("file4", reduxState.form.grantAmbassadorform.values.file4);
        urlencoded.append("file5", reduxState.form.grantAmbassadorform.values.file5);
        urlencoded.append("file6", reduxState.form.grantAmbassadorform.values.file6);
        urlencoded.append("file7", reduxState.form.grantAmbassadorform.values.file7);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/editambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const sendRevisionGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.grantReducer.edit_grant_ambassador.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/revisionambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SEND_REVISION_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFUL_SEND });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const sendCorrectionGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.grantReducer.show_grant_ambassador.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("correction", reduxState.form.grantAmbassadorform.values.correction);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/correctionambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SEND_CORRECTION_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const sendRejectGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.grantReducer.show_grant_ambassador.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("correction", reduxState.form.grantAmbassadorform.values.correction);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/rejectambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SEND_REJECT_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const sendApprovedGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.grantReducer.show_grant_ambassador.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("correction", reduxState.form.grantAmbassadorform.values.correction);
        urlencoded.append("amount", reduxState.form.grantAmbassadorform.values.amount);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/approveambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SEND_APPROVED_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrant = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_administrator", reduxState.loginReducer.active_user.id);
        urlencoded.append("title", reduxState.form.grantNewform.values.title);
        urlencoded.append("language", reduxState.form.grantNewform.values.language);
        urlencoded.append("description", reduxState.form.grantNewform.values.description);
        urlencoded.append("state", reduxState.form.grantNewform.values.state);
        urlencoded.append("type", reduxState.form.grantNewform.values.type);
        urlencoded.append("deadline_date", convertDate(reduxState.form.grantNewform.values.deadline_date));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_GRANT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrantAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("id_grant", reduxState.grantReducer.show_grant.id);
        urlencoded.append("id", reduxState.grantReducer.new_grant_ambassador.id);
        urlencoded.append("code", reduxState.form.grantAmbassadorNewform.values.code);
        urlencoded.append("number", reduxState.form.grantAmbassadorNewform.values.number);
        urlencoded.append("question1", reduxState.form.grantAmbassadorNewform.values.question1);
        urlencoded.append("question2", reduxState.form.grantAmbassadorNewform.values.question2);
        urlencoded.append("question3", reduxState.form.grantAmbassadorNewform.values.question3);
        urlencoded.append("question4", reduxState.form.grantAmbassadorNewform.values.question4);
        urlencoded.append("question5", reduxState.form.grantAmbassadorNewform.values.question5);
        urlencoded.append("question6", reduxState.form.grantAmbassadorNewform.values.question6);
        urlencoded.append("question7", reduxState.form.grantAmbassadorNewform.values.question7);
        urlencoded.append("question8", reduxState.form.grantAmbassadorNewform.values.question8);
        urlencoded.append("question9", reduxState.form.grantAmbassadorNewform.values.question9);
        urlencoded.append("question10", reduxState.form.grantAmbassadorNewform.values.question10);
        urlencoded.append("question11", reduxState.form.grantAmbassadorNewform.values.question11);
        urlencoded.append("question12", reduxState.form.grantAmbassadorNewform.values.question12);
        urlencoded.append("question13", reduxState.form.grantAmbassadorNewform.values.question13);
        urlencoded.append("question14", reduxState.form.grantAmbassadorNewform.values.question14);
        urlencoded.append("question15", reduxState.form.grantAmbassadorNewform.values.question15);
        urlencoded.append("file", reduxState.form.grantAmbassadorNewform.values.file);
        urlencoded.append("file2", reduxState.form.grantAmbassadorNewform.values.file2);
        urlencoded.append("file3", reduxState.form.grantAmbassadorNewform.values.file3);
        urlencoded.append("file4", reduxState.form.grantAmbassadorNewform.values.file4);
        urlencoded.append("file5", reduxState.form.grantAmbassadorNewform.values.file5);
        urlencoded.append("file6", reduxState.form.grantAmbassadorNewform.values.file6);
        urlencoded.append("file7", reduxState.form.grantAmbassadorNewform.values.file7);

        if (reduxState.grantReducer.show_grant.type != "state.growth" && reduxState.grantReducer.show_grant.type != "state.scholarship") {
            if (reduxState.form.mentoredAmbassadorform.values != undefined) {
                urlencoded.append("mentoredambassador", reduxState.form.mentoredAmbassadorform.values.mentoredambassador);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/newambassador", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_GRANT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrantAmbassadorCooperative = (activeWarning) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("id_grant", reduxState.grantReducer.show_grant.id);
        urlencoded.append("id", reduxState.grantReducer.new_grant_ambassador.id);
        urlencoded.append("code", undefined);
        urlencoded.append("number", reduxState.form.grantAmbassadorNewform.values.number);
        urlencoded.append("question1", reduxState.form.grantAmbassadorNewform.values.question1);
        urlencoded.append("question2", reduxState.form.grantAmbassadorNewform.values.question2);
        urlencoded.append("question3", reduxState.form.grantAmbassadorNewform.values.question3);
        urlencoded.append("question4", reduxState.form.grantAmbassadorNewform.values.question4);
        urlencoded.append("question5", reduxState.form.grantAmbassadorNewform.values.question5);
        urlencoded.append("question6", reduxState.form.grantAmbassadorNewform.values.question6);
        urlencoded.append("question7", reduxState.form.grantAmbassadorNewform.values.question7);
        urlencoded.append("question8", reduxState.form.grantAmbassadorNewform.values.question8);
        urlencoded.append("question9", reduxState.form.grantAmbassadorNewform.values.question9);
        urlencoded.append("question10", reduxState.form.grantAmbassadorNewform.values.question10);
        urlencoded.append("question11", reduxState.form.grantAmbassadorNewform.values.question11);
        urlencoded.append("question12", reduxState.form.grantAmbassadorNewform.values.question12);
        urlencoded.append("question13", reduxState.form.grantAmbassadorNewform.values.question13);
        urlencoded.append("question14", reduxState.form.grantAmbassadorNewform.values.question14);
        urlencoded.append("question15", reduxState.form.grantAmbassadorNewform.values.question15);
        urlencoded.append("file", reduxState.form.grantAmbassadorNewform.values.file);
        urlencoded.append("file2", reduxState.form.grantAmbassadorNewform.values.file2);
        urlencoded.append("file3", reduxState.form.grantAmbassadorNewform.values.file3);
        urlencoded.append("file4", reduxState.form.grantAmbassadorNewform.values.file4);
        urlencoded.append("file5", reduxState.form.grantAmbassadorNewform.values.file5);
        urlencoded.append("file6", reduxState.form.grantAmbassadorNewform.values.file6);
        urlencoded.append("file7", reduxState.form.grantAmbassadorNewform.values.file7);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/newambassador", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_AMBASSADOR, payload: json.data });
                    if (activeWarning) { dispatch({ type: SUCCESSFULL_NEW }) };
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrantUpdate = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_grant_ambassador", key);
        urlencoded.append("id_user", reduxState.loginReducer.active_user.id);
        urlencoded.append("file", reduxState.form.grantUpdateForm.values.file);
        urlencoded.append("description", reduxState.form.grantUpdateForm.values.description);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/newupdate", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_GRANT_UPDATE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrantGroup = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_grant_ambassador", key);
        urlencoded.append("groups", jsonToArray(reduxState.form.grantGroupform.values.groups));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/newgrantgroups", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_GRANT_GROUP, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const deleteGrant = (key, redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grant/delete/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_GRANT, payload: json.data });
                    redirect.push('/grant');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const uploadImageAlert = (key) => ({ type: MBS_IMAGE_ALERT, payload: key })

export const uploadImage = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.grantReducer.id_student);
        urlencoded.append("file_name", reduxState.form.uploadform.values.name_image);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/programmbs/newfile", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UPLOAD_IMAGE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const deleteImageAlert = () => ({ type: DELETE_IMAGE_ALERT })

export const showGrantRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.grantReducer.new_grant.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push('/grant/show/' + key);
    }
}

export const applicationGrantRedirect = () => {
    return (dispatch, getState) => {
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push('/grant/ambassador');
    }
}

export const bankInformationRedirect = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const user = reduxState.loginReducer.active_user;
        const url = user.transfer != undefined ? '/transfer/show/' + user.id : '/transfer/new';
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return history.push(url);
    }
}

export const mentoredAmbassadorList = (grant_id) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);


        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", reduxState.loginReducer.active_user.id);
        urlencoded.append("id_grant", grant_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/mentoredambassador/list", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: MENTORED_AMBASSADOR_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const mentoredAmbassador = (grant_id) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);


        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", grant_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grant/mentoredambassador", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: MENTORED_AMBASSADOR, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const listGrantRequirement = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grantrequirement/", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANT_REQUIREMENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const newGrantRequirement = (isCustom) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("activeTime", reduxState.form.grantRequirementNewform.values.active_time);
        urlencoded.append("receiptTime", reduxState.form.grantRequirementNewform.values.receipt_time);
        urlencoded.append("mbsGraduates", reduxState.form.grantRequirementNewform.values.mbs_graduates);
        urlencoded.append("saGraduates", reduxState.form.grantRequirementNewform.values.sa_graduates);
        urlencoded.append("nsaGraduates", reduxState.form.grantRequirementNewform.values.nsa_graduates);
        urlencoded.append("initial_date", reduxState.form.grantRequirementNewform.values.initial_date);
        urlencoded.append("type", reduxState.form.grantRequirementNewform.values.type);
        urlencoded.append("customized", isCustom);
        if (isCustom) {
            urlencoded.append("country", reduxState.form.grantRequirementNewform.values.country);
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/grantrequirement/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showGrantRequirement = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grantrequirement/show/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_GRANT_REQUIREMENT, payload: json.data });
                    dispatch({ type: LOAD_FORM_GRANT_REQUIREMENT, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });

    }
};
export const loadFormGrantRequirement = data => ({ type: LOAD_FORM_GRANT_REQUIREMENT, data });

export const editGrantRequirement = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.grantRequirementEditform.values.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("activeTime", reduxState.form.grantRequirementEditform.values.active_time);
        urlencoded.append("receiptTime", reduxState.form.grantRequirementEditform.values.receipt_time);
        urlencoded.append("mbsGraduates", reduxState.form.grantRequirementEditform.values.mbs_graduates);
        urlencoded.append("saGraduates", reduxState.form.grantRequirementEditform.values.sa_graduates);
        urlencoded.append("nsaGraduates", reduxState.form.grantRequirementEditform.values.nsa_graduates);
        urlencoded.append("initial_date", reduxState.form.grantRequirementEditform.values.initial_date);
        urlencoded.append("type", reduxState.form.grantRequirementEditform.values.type);
        urlencoded.append("customized", reduxState.form.grantRequirementEditform.values.customized);
        urlencoded.append("country", reduxState.form.grantRequirementEditform.values.country);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/grantrequirement/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const deleteGrantRequirement = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/grantrequirement/delete/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    history.push('/grantrequirement');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getGrantMemberList = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_ambassador_id", key);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grantmember/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GRANTMEMBER_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
export const cleanGrantMemberList = () => ({ type: CLEAN_GRANTMEMBER_LIST })
export const cleanAddMemberWarning = () => ({ type: CLEAN_ADD_MEMBER_WARNING })

export const newGrantMember = (userId, isAmbassador) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("user_id", userId);
        urlencoded.append("grant_ambassador_id", reduxState.grantReducer.show_grant_ambassador.id);
        urlencoded.append("is_ambassador", isAmbassador);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/grantmember/new?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else if (json.code === 500) {
                    dispatch({ type: MEMBER_ALREADY_ADDED });
                    // }else if (json.code === 500) {
                    //     dispatch({ type: ERROR_ADD_GRANTMEMBER });
                } else {
                    dispatch(getGrantMemberList(reduxState.grantReducer.show_grant_ambassador.id));
                }
            })
            .catch((e) => {
                dispatch({ type: MEMBER_ALREADY_ADDED });
                // history.push("/login");
            });
    }
}

export const deleteGrantMember = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        let grantAmbassadorId = reduxState.grantReducer.show_grant_ambassador.id
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(`${reduxState.loginReducer.base_url}/grantmember/delete/${key}/${grantAmbassadorId}?callback=foo`, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch(getGrantMemberList(reduxState.grantReducer.show_grant_ambassador.id));
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
